export const getAllEmployees = async (token, parameter) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/employees/get`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        name: parameter
      })
    });

    if (!response.ok) {
      throw new Error(`Failed operation: ${response.statusText}`);
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const getAllClients = async (token, parameter) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/clients/get`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        name: parameter
      })
    });

    if (!response.ok) {
      throw new Error(`Failed operation: ${response.statusText}`);
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const createClient = async (token, newUser) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/clients`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(newUser)
    }
    );

    if (!response.ok) {
      throw new Error(`Failed operation: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const createEmployee = async (token, newUser) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/employees`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(newUser)
    }
    );

    if (!response.ok) {
      throw new Error(`Failed operation: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const getUserById = async (token, idUser) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/users/${idUser}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    );

    if (!response.ok) {
      throw new Error(`Failed operation: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const checkClientEstablishment = async (dniClient, uuidEstablishment) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/clients/dni/${dniClient}/${uuidEstablishment}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        //'Authorization': `Bearer ${token}`
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw { message: errorData.message, code: response.status };
    }

    const data = await response.json();
    return { data, response };
  } catch (error) {
    throw error;
  }
};


export const deleteUserById = async (token, idUser) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/users/${idUser}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    );

    if (!response.ok) {
      throw new Error(`Failed operation: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const editClient = async (token, idUser, editedClient) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/clients/${idUser}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(editedClient)
    }
    );

    if (!response.ok) {
      throw new Error(`Failed operation: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const editEmployee = async (token, idUser, editedEmployee) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/employees/${idUser}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(editedEmployee)
    }
    );

    if (!response.ok) {
      throw new Error(`Failed operation: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};