import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Card, Col, Container, Form, InputGroup, ListGroup, Modal, Row, ToggleButton } from 'react-bootstrap';
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { createUser, editCloseMessage, selectErrorMessage, selectSuccessMessage } from '../../redux/reducers/usersReducer';
import { fetchProfiles, selectProfiles } from '../../redux/reducers/profileReducer';
import { selectLoggedUserProfile } from '../../redux/reducers/authReducer';
import { fetchCompanies, selectCompanies, selectCompaniesError, selectCompaniesLoading } from '../../redux/reducers/companiesReducer';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.module.css"
import { fetchEstablishments, selectEstablishments, selectEstablishmentsError, selectEstablishmentsLoading } from '../../redux/reducers/establishmentsReducer';

const NewUserModal = ({ showNewUserModal, handleClose }) => {

  // Llamadas a API y Redux
  const dispatch = useDispatch();
  const profilesAuth = useSelector(selectLoggedUserProfile);

  // Comprobaciones de acceso
  const hasManageClientsAccess = profilesAuth?.some(profile =>
    profile.accesses?.some(access => access.name === 'manage_clients')
  );

  const hasManageEmployeesAccess = profilesAuth?.some(profile =>
    profile.accesses?.some(access => access.name === 'manage_employees')
  );

  const hasAssignProfilesAccess = profilesAuth?.some(profile =>
    profile.accesses?.some(access => access.name === 'assign_profiles')
  );

  // Inicialización del tipo de usuario basado en accesos
  const initialUserType = hasManageEmployeesAccess && !hasManageClientsAccess
    ? '1'
    : !hasManageEmployeesAccess && hasManageClientsAccess
      ? '2'
      : '1';

  const [newUserType, setNewUserType] = useState(initialUserType);
  const [dniError, setDniError] = useState(null);
  const [dniIsValid, setDniIsValid] = useState(null);
  const [usernameIsValid, setUsernameIsValid] = useState(null);

  //Compañías
  const loadingCompanies = useSelector(selectCompaniesLoading);
  const companies = useSelector(selectCompanies);
  const errorCompanies = useSelector(selectCompaniesError);
  useEffect(() => {
    dispatch(fetchCompanies(''));
  }, [dispatch]);

  const handleSelectCompany = (event) => {
    const selectedCompanyUUID = event.target.value;
    setFormData(prevFormData => ({
      ...prevFormData,
      uuid_company: selectedCompanyUUID
    }));
  };

  // Mensaje de éxito o error
  const successMessage = useSelector(selectSuccessMessage);
  const errorMessage = useSelector(selectErrorMessage);

  // Formulario
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    phone: '',
    username: '',
    password: '',
    dni: '',
    email: '',
    uuid_company: '',
    position: '',
    birth_date: '',
    profiles: [],
    establishments: []
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    let formattedValue = value;

    if (name === 'phone' || name === 'dni') {
      formattedValue = value.replace(/\D/g, '');
    }

    setFormData({
      ...formData,
      [name]: formattedValue
    });

    if (name === 'dni' && (formattedValue.length < 6 || formattedValue.length > 9)) {
      setDniError('El DNI debe tener entre 6 y 9 caracteres.');
    } else {
      setDniError(null);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Perfiles
  const profiles = useSelector(selectProfiles);
  useEffect(() => {
    dispatch(fetchProfiles(null));
  }, [dispatch]);

  const chunkArray = (arr, chunkSize) => {
    const arrCopy = [...arr];
    arrCopy.sort((a, b) => a.name.localeCompare(b.name));

    const chunks = [];
    for (let i = 0; i < arrCopy.length; i += chunkSize) {
      chunks.push(arrCopy.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const chunkedProfiles = chunkArray(profiles, 4);

  const handleProfileCheckboxChange = (event) => {
    const { id, checked } = event.target;
    if (checked) {
      setFormData(prevFormData => ({
        ...prevFormData,
        profiles: [...prevFormData.profiles, id]
      }));
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        profiles: prevFormData.profiles.filter(profileId => profileId !== id)
      }));
    }
  };

  // Establecimientos
  const loadingEstablishments = useSelector(selectEstablishmentsLoading);
  const establishments = useSelector(selectEstablishments);
  const errorEstablishments = useSelector(selectEstablishmentsError);
  useEffect(() => {
    dispatch(fetchEstablishments(''));
  }, [dispatch]);

  const [selectedEstablishments, setSelectedEstablishments] = useState([]);
  const [availableEstablishments, setAvailableEstablishments] = useState([]);
  useEffect(() => {
    setAvailableEstablishments(establishments);
  }, [establishments]);

  const handleSelectEstablishment = (event) => {
    const selectedEstablishmentId = event.target.value;
    const selectedEstablishment = availableEstablishments.find(establishment => establishment.uuid === selectedEstablishmentId);

    setSelectedEstablishments([...selectedEstablishments, selectedEstablishment]);
    setFormData({
      ...formData,
      establishments: [...formData.establishments, selectedEstablishmentId]
    });

    setAvailableEstablishments(availableEstablishments.filter(establishment => establishment.uuid !== selectedEstablishmentId));
  };

  const handleRemoveEstablishment = (establishmentToRemove) => {
    setAvailableEstablishments([...availableEstablishments, establishmentToRemove]);

    setSelectedEstablishments(selectedEstablishments.filter(establishment => establishment.uuid !== establishmentToRemove.uuid));
    setFormData({
      ...formData,
      establishments: formData.establishments.filter(establishmentId => establishmentId !== establishmentToRemove.uuid)
    });
  };

  // FECHAS
  function range(start, end, step = 1) {
    const result = [];
    for (let i = start; i < end; i += step) {
      result.push(i);
    }
    return result;
  }
  const years = range(1940, new Date().getFullYear(), 1);
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const handleCloseMessage = () => {
    dispatch(editCloseMessage());
    if (successMessage) {
      setFormData({
        first_name: '',
        last_name: '',
        phone: '',
        username: '',
        password: '',
        dni: '',
        uuid_company: '',
        email: '',
        position: '',
        birth_date: '',
        profiles: [],
        establishments: []
      });
      setSelectedEstablishments([]);
      setAvailableEstablishments([]);
      handleClose();
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(createUser(newUserType, formData));
  };

  const checkDniExists = async () => {
    if (formData.dni.length >= 6 && formData.dni.length <= 9) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/users/check-dni/${formData.dni}`);
        const data = await response.json();
        if (data.is_valid) {
          setDniIsValid(true);
          setDniError(null); // Limpiar cualquier error previo si es válido
        } else {
          setDniIsValid(false);
          setDniError('El DNI ya está registrado.');
        }
      } catch (error) {
        console.error("Error checking DNI:", error);
      }
    }
  };

  const checkUsernameExists = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/users/check-username/${formData.username}`);
      const data = await response.json();
      if (data.is_valid) {
        setUsernameIsValid(true);
      } else {
        setUsernameIsValid(false);
      }
    } catch (error) {
      console.error("Error checking username:", error);
    }
  };

  return (
    <div>
      <Modal show={showNewUserModal} onHide={handleClose} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Nuevo usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ marginLeft: '8px', marginRight: '8px' }}>
          <form style={{ display: 'contents' }} onSubmit={handleSubmit}>
            <div>
              <h4>Información personal</h4>
              <Row className='pb-1'>
                <Col sm='4' md='4' lg='4'>
                  <Form.Control
                    name='first_name'
                    type="text"
                    placeholder="Nombre..."
                    value={formData.first_name}
                    onChange={handleFormChange}
                    style={{ marginTop: 0, height: '100%' }}
                    required
                  />
                </Col>
                <Col sm='4' md='4' lg='4'>
                  <Form.Control
                    name='last_name'
                    type="text"
                    placeholder="Apellido..."
                    value={formData.last_name}
                    onChange={handleFormChange}
                    style={{ marginTop: 0, height: '100%' }}
                    required
                  />
                </Col>
                <Col sm='4' md='4' lg='4'>
                  <Form.Control
                    name='phone'
                    type="text"
                    placeholder="Teléfono..."
                    value={formData.phone}
                    onChange={handleFormChange}
                    style={{ marginTop: 0, height: '100%' }}
                    required
                    maxLength={'10'}
                  />
                </Col>
                <Col sm='4' md='4' lg='4' className='mt-3'>
                  <InputGroup hasValidation>
                    <Form.Control
                      name='dni'
                      type="text"
                      placeholder="DNI..."
                      value={formData.dni}
                      onChange={handleFormChange}
                      onBlur={checkDniExists}
                      style={{ marginTop: 0, height: '100%' }}
                      maxLength={9}
                      required
                      isInvalid={dniError}
                      isValid={dniIsValid}
                    />
                    <Form.Control.Feedback type="invalid">
                      {dniError}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="valid">
                      DNI disponible.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Col>
                <Col sm='4' md='4' lg='4' className='mt-3'>
                  <Form.Control
                    name='email'
                    type="text"
                    placeholder="Email..."
                    value={formData.email}
                    onChange={handleFormChange}
                    style={{ marginTop: 0, height: '100%' }}
                    required
                  />
                </Col>
              </Row>
              <h4 className='pt-3'>Usuario</h4>
              <Row className='pb-4'>
                <Col sm='3' md='3' lg='3'>
                  <InputGroup hasValidation>
                    <Form.Control
                      name='username'
                      type="text"
                      placeholder="Nombre de usuario..."
                      value={formData.username}
                      onChange={handleFormChange}
                      onBlur={checkUsernameExists}
                      style={{ marginTop: 0, height: '100%' }}
                      required
                      isValid={usernameIsValid}
                      isInvalid={usernameIsValid === false}
                    />
                    <Form.Control.Feedback type="valid">
                      Nombre de usuario disponible.
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Nombre de usuario no disponible.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Col>
                <Col sm='6' md='6' lg='6'>
                  <InputGroup style={{ height: '100%' }}>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      name='password'
                      placeholder="Contraseña..."
                      value={formData.password}
                      onChange={handleFormChange}
                      style={{ flex: '1', height: '100%', borderTopRightRadius: 0, borderBottomRightRadius: 0, marginTop: 0 }}
                      required
                    />
                    <Button
                      variant="secondary"
                      onClick={togglePasswordVisibility}
                      style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, height: '100%', width: '60px' }}
                    >
                      {showPassword ? <EyeFill size={'25'} /> : <EyeSlashFill size={'25'} />}
                    </Button>
                  </InputGroup>
                </Col>
              </Row>

              <Container className='pb-4 d-flex justify-content-center'>
                <ButtonGroup toggle>
                  {hasManageEmployeesAccess && (
                    <ToggleButton
                      id={'userType-1'}
                      type="radio"
                      variant={'outline-primary'}
                      name="usertype"
                      value='1'
                      checked={newUserType === '1'}
                      onChange={() => setNewUserType('1')}
                    >
                      Colaborador
                    </ToggleButton>
                  )}
                  {hasManageClientsAccess && (
                    <ToggleButton
                      id={'userType-2'}
                      type="radio"
                      variant={'outline-primary'}
                      name="usertype"
                      value='2'
                      checked={newUserType === '2'}
                      onChange={() => setNewUserType('2')}
                    >
                      Cliente
                    </ToggleButton>
                  )}
                </ButtonGroup>
              </Container>

              {newUserType === '1' && hasManageEmployeesAccess ? (
                <>
                  <h4 className='pb-1'>Colaborador</h4>
                  <Form.Control
                    name='position'
                    type="text"
                    placeholder="Puesto de trabajo..."
                    value={formData.position}
                    onChange={handleFormChange}
                    style={{ marginTop: 0, height: '100%' }}
                    required
                    className='mb-5'
                  />
                  {hasAssignProfilesAccess
                    && (
                      <>
                        <h4>Perfiles del usuario</h4>
                        <p className='pb-2'>Seleccione el o los perfiles asociados al usuario</p>
                        {chunkedProfiles.map((chunk, index) => (
                          <Row key={index} className="mb-3" style={{ marginLeft: '1rem' }}>
                            {chunk.map(profile => (
                              <Col key={profile.uuid} md={3} className="pb-3">
                                <Form.Check
                                  type="checkbox"
                                  id={profile.uuid}
                                  label={profile.name}
                                  onChange={handleProfileCheckboxChange}
                                  checked={formData.profiles.includes(profile.uuid)}
                                />
                              </Col>
                            ))}
                          </Row>
                        ))}
                      </>
                    )}

                </>
              ) : newUserType === '2' && hasManageClientsAccess ? (
                <>
                  <h4 className='pb-1'>Cliente</h4>
                  <Row className='pb-4'>
                    <Col sm='4' md='4' lg='4'>
                      <DatePicker
                        required
                        dateFormat="yyyy/MM/dd"
                        placeholderText='Fecha de nacimiento'
                        className='form-control'
                        style={{
                          width: "100%", // Asegura que el DatePicker ocupe todo el ancho
                        }}
                        renderCustomHeader={({
                          date,
                          changeYear,
                          changeMonth,
                        }) => (
                          <div
                            style={{
                              margin: 10,
                              display: "block", // Asegura que los selects estén en columnas, uno debajo del otro
                              width: "100%", // Asegura que el contenedor ocupe todo el ancho
                            }}
                          >
                            <select
                              style={{ width: '100%', marginBottom: '10px' }}  // El select ocupa el 100% del ancho y tiene margen inferior
                              value={date.getFullYear()}
                              onChange={({ target: { value } }) => changeYear(value)}
                            >
                              {years.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <select
                              style={{ width: '100%' }}  // El select ocupa el 100% del ancho
                              value={months[date.getMonth()]}
                              onChange={({ target: { value } }) =>
                                changeMonth(months.indexOf(value))
                              }
                            >
                              {months.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                        selected={formData.birth_date}
                        onChange={(date) => setFormData({ ...formData, birth_date: new Date(date.toISOString()) })}
                      />
                    </Col>

                    {loadingCompanies ? (
                      <p>Cargando compañías...</p>
                    ) : errorCompanies ? (
                      <p>Error: {errorCompanies}</p>
                    ) : companies.length === 0 ? (
                      <p>No se encontraron compañías</p>
                    ) : (
                      <Col m='4' md='4' lg='4'>
                        <Form.Select aria-label="Selecciona una compañía" className='form-control' onChange={handleSelectCompany} value={formData.uuid_company} required>
                          <option value="">Selecciona una compañía</option>
                          {companies.map(company => (
                            <option key={company.uuid} value={company.uuid}>{company.name}</option>
                          ))}
                        </Form.Select>
                      </Col>
                    )}
                  </Row>
                </>
              ) : (
                <p>No tiene acceso para crear este tipo de usuario.</p>
              )}
              <h4 className='pb-1'>Establecimientos</h4>
              {loadingEstablishments ? (
                <p>Cargando establecimientos...</p>
              ) : errorEstablishments ? (
                <p>Error: {errorEstablishments}</p>
              ) : establishments.length === 0 ? (
                <p>No se encontraron establecimientos.</p>
              ) : (
                <Row>
                  <Col m='7' md='7' lg='7'>
                    <ListGroup>
                      {selectedEstablishments.length === 0 ? (
                        <p>Sin establecimientos seleccionados aún</p>
                      ) : (selectedEstablishments.map(establishment => (
                        <ListGroup.Item key={establishment.uuid} className="d-flex justify-content-between align-items-center mb-3">
                          <div>
                            <Card.Text style={{ marginBottom: '0rem' }}><strong>{establishment.name}</strong></Card.Text>
                            <Card.Text>Código: {establishment.code}</Card.Text>
                          </div>
                          <div className="d-flex">
                            <Button variant="danger" size="md" onClick={() => handleRemoveEstablishment(establishment)}>x</Button>
                          </div>
                        </ListGroup.Item>
                      )))}
                    </ListGroup>
                  </Col>
                  <Col m='5' md='5' lg='5' className='pb-3'>
                    <Form.Select aria-label="Agregar establecimientos asociados" className='form-control' onChange={handleSelectEstablishment}>
                      <option value="">Agregar establecimientos asociados</option>
                      {availableEstablishments.map(establishment => (
                        <option key={establishment.uuid} value={establishment.uuid}>{establishment.name}</option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>
              )}
            </div>
            <Modal.Footer>
              <Button variant="primary" type='submit'>Crear usuario</Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
        <Modal show={!!successMessage || !!errorMessage} onHide={handleCloseMessage}>
          <Modal.Header closeButton>
            <Modal.Title>{errorMessage ? 'Error en la creación' : 'Creación exitosa'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {successMessage || errorMessage}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseMessage}>Cerrar</Button>
          </Modal.Footer>
        </Modal>
      </Modal>
    </div>
  );
}

export default NewUserModal;
