import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { deleteUser } from '../../redux/reducers/usersReducer';

const DeleteUserModal = ({ showDeleteModal, handleClose, user }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // Extrae el uuid del user si existe
  const userUuid = user?.user?.uuid;

  const handleDelete = () => {
    console.log(userUuid);
    if (!userUuid) {
      console.error('El UUID del usuario no está definido');
      return;
    }

    setLoading(true);
    dispatch(deleteUser(userUuid)).finally(() => {
      setLoading(false);
      handleClose();
    });
  };

  return (
    <>
      {user && (
        <Modal show={showDeleteModal} onHide={handleClose} size='md'>
          <Modal.Header closeButton>
            {user.employee ? (
              <Modal.Title>¿Desea borrar el usuario {user.employee.first_name} {user.employee.last_name}?</Modal.Title>
            ) : user.client && (
              <Modal.Title>¿Desea borrar el usuario {user.client.first_name} {user.client.last_name}?</Modal.Title>
            )}
          </Modal.Header>
          <Modal.Body>
            <p>Se borrará el usuario con todos los accesos asociados.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleDelete} disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : 'Borrar'}
            </Button>
            <Button variant="outline-primary" onClick={handleClose} disabled={loading}>
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal >
      )}
    </>
  );
}

export default DeleteUserModal;
