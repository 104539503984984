import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, InputGroup, ListGroup, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createNewOrder, editCloseMessage, fetchOrders, selectErrorMessage, selectSuccessMessage } from '../../redux/reducers/ordersReducer';
import { PersonCircle } from 'react-bootstrap-icons';
import { fetchEstablishments, selectEstablishments, selectEstablishmentsError, selectEstablishmentsLoading } from '../../redux/reducers/establishmentsReducer';
import { checkClientEstablishment } from '../../redux/actions/usersThunks';
import DatePicker from 'react-datepicker';
import { fetchProducts, fetchProductsByEstablishment, selectProducts, selectProductsError, selectProductsLoading } from '../../redux/reducers/productsReducer';
import Select from 'react-select';

const NewOrderModal = ({ showNewOrderModal, handleClose }) => {
  const dispatch = useDispatch();

  const successMessage = useSelector(selectSuccessMessage);
  const errorMessage = useSelector(selectErrorMessage);

  const [formData, setFormData] = useState({
    client_dni: '',
    uuid_client: '',
    uuid_establishment: '',
    purchase_date: '',
    details: []
  });

  //FORM
  const handleFormChange = (event) => {
    const { name, value } = event.target;
    const sanitizedValue = (name === 'client_dni' || name === 'dni') ? value.replace(/\D/g, '') : value;
    setFormData({
      ...formData,
      [name]: sanitizedValue
    });
  };

  const isFormEmpty = !formData.uuid_client || formData.details.length === 0 || !formData.purchase_date

  const [checkedUser, setCheckedUser] = useState(null);
  const [verificationMessage, setVerificationMessage] = useState('');

  //FORM - user verif
  const handleVerification = async () => {
    setCheckedUser(null);
    dispatch(fetchProductsByEstablishment(formData.uuid_establishment));
    setFormData({ ...formData, uuid_client: '' })
    try {
      const user = await checkClientEstablishment(formData.client_dni, formData.uuid_establishment);
      setCheckedUser(user.data);
      setFormData({ ...formData, uuid_client: user.data.uuid })
    } catch (error) {
      if (error.message) {
        setVerificationMessage(`Error: ${error.message}`);
      } else {
        setVerificationMessage('Error inesperado.');
      }
    }
  };

  const closeResponseModal = () => {
    setVerificationMessage('');
  };

  //ESTABLISHMENTS
  const loadingEstablishments = useSelector(selectEstablishmentsLoading)
  const establishments = useSelector(selectEstablishments)
  const errorEstablishments = useSelector(selectEstablishmentsError)
  useEffect(() => {
    dispatch(fetchEstablishments(''));
  }, [dispatch]);

  //DATES
  function range(start, end, step = 1) {
    const result = [];
    for (let i = start; i < end; i += step) {
      result.push(i);
    }
    return result;
  }
  const years = range(2020, new Date().getFullYear() + 1, 1);
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  //PRODUCTS
  const loadingProducts = useSelector(selectProductsLoading);
  const products = useSelector(selectProducts);
  const errorProducts = useSelector(selectProductsError);
  // useEffect(() => {
  //     dispatch(fetchProductsByEstablishment());
  // }, [dispatch]);

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [availableProducts, setAvailableProducts] = useState([]);
  useEffect(() => {
    setAvailableProducts(products);
  }, [products]);

  const options = availableProducts.map(product => ({
    value: product.uuid,
    label: product.name
  }));

  const handleChange = selectedOption => {
    handleSelectProduct({
      target: {
        name: 'product',
        value: selectedOption ? selectedOption.value : ''
      }
    });
  };

  const handleSelectProduct = (event) => {
    const selectedProductId = event.target.value;
    const selectedProduct = availableProducts.find(product => product.uuid === selectedProductId);

    const updatedSelectedProducts = [...selectedProducts, { ...selectedProduct, quantity: 1, description: null }];
    const updatedFormDataDetails = [...formData.details, { product_uuid: selectedProductId, quantity: 1, description: null }];

    setSelectedProducts(updatedSelectedProducts);
    setFormData({
      ...formData,
      details: updatedFormDataDetails
    });
    const updatedAvailableProducts = availableProducts.filter(product => product.uuid !== selectedProductId);
    setAvailableProducts(updatedAvailableProducts);
  };

  const handleRemoveProduct = (productToRemove) => {
    setAvailableProducts([...availableProducts, productToRemove]);

    setSelectedProducts(selectedProducts.filter(product => product.uuid !== productToRemove.uuid));
    setFormData({
      ...formData,
      details: formData.details.filter(product => product.product_uuid !== productToRemove.uuid)
    });
  };

  const handleQuantityChange = (event, productUuid) => {
    const newQuantity = parseInt(event.target.value);
    const updatedSelectedProducts = selectedProducts.map(product => {
      if (product.uuid === productUuid) {
        return { ...product, quantity: newQuantity, description: null };
      }
      return product;
    });
    const updatedFormDataDetails = formData.details.map(product => {
      if (product.product_uuid === productUuid) {
        const updatedProduct = { ...product, quantity: newQuantity, description: null };
        return updatedProduct;
      }
      return product;
    });
    setSelectedProducts(updatedSelectedProducts);
    setFormData({
      ...formData,
      details: updatedFormDataDetails
    });
  };

  const handleCloseModal = () => {
    setSelectedProducts([]);
    setFormData({
      client_dni: '',
      uuid_client: '',
      uuid_establishment: '',
      purchase_date: '',
      details: []
    })
    setCheckedUser(null)
    handleClose();
  }

  const handleCloseMessage = () => {
    dispatch(editCloseMessage());
    if (successMessage) {
      setSelectedProducts([]);
      setAvailableProducts(products);
      setFormData({
        client_dni: '',
        uuid_client: '',
        uuid_establishment: '',
        purchase_date: '',
        details: []
      })
      handleClose();
      setCheckedUser(null);
    }
  }

  const handleDniChange = (e) => {
    const value = e.target.value;
    // Permitir solo caracteres numéricos
    if (/^\d*$/.test(value)) {
      handleFormChange(e);
    }
  };

  const isDniValid = formData.client_dni.length >= 7 && formData.client_dni.length <= 9;

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(createNewOrder(formData));
    dispatch(fetchOrders({
      start_date: '',
      end_date: '',
      uuid_status: '',
      search_parameter: ''
    }))
  };

  return (
    <>
      <Modal show={showNewOrderModal} onHide={handleCloseModal} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Nuevo pedido</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ marginLeft: '8px' }}>
          <form style={{ display: 'contents' }} onSubmit={handleSubmit}>
            <div>
              <Row className='pb-2'>
                <Col sm='6' md='6' lg='6'>
                  <h5>Establecimiento</h5>
                  {loadingEstablishments ? (
                    <p>Cargando establecimientos...</p>
                  ) : errorEstablishments ? (
                    <p>Error: {errorEstablishments}</p>
                  ) : establishments.length === 0 ? (
                    <p>No se encontraron establecimientos.</p>
                  ) : (
                    <Form.Select
                      aria-label="Seleccione establecimiento"
                      className='form-control'
                      onChange={(event) => {
                        const selectedUuid = event.target.value;
                        setFormData(prevFormData => ({
                          ...prevFormData,
                          uuid_establishment: selectedUuid
                        }));
                      }}
                      required
                    >
                      <option value="">Seleccione establecimiento</option>
                      {establishments.map(establishment => (
                        <option key={establishment.uuid} value={establishment.uuid}>{establishment.name}</option>
                      ))}
                    </Form.Select>
                  )}
                </Col>
                <Col sm='6' md='6' lg='6'>
                  <h5>Cliente</h5>
                  <InputGroup>
                    <Form.Control
                      name='client_dni'
                      type="text"
                      placeholder={formData.uuid_establishment === '' ? ("Seleccione un establecimiento") : ("DNI...")}
                      value={formData.client_dni}
                      onChange={handleDniChange}
                      style={{ marginTop: 0 }}
                      disabled={formData.uuid_establishment === ''}
                      required
                    />
                    <Button
                      variant='secondary'
                      id="search-button"
                      disabled={formData.uuid_establishment === '' || !isDniValid}
                      onClick={handleVerification}
                    >
                      Verificar
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
              <Card>
                <Card.Body>
                  <Row className="d-flex align-items-center justify-content-between">
                    {checkedUser ? (
                      <>
                        <Col sm='1' md='1' lg='1'>
                          <PersonCircle size={35} />
                        </Col>
                        <Col sm='3' md='3' lg='3'>
                          {checkedUser.first_name} {checkedUser.last_name}
                        </Col>
                        <Col sm='3' md='3' lg='3'>
                          <strong>{checkedUser.dni}</strong>
                        </Col>
                        <Col sm='3' md='3' lg='3'>
                          <strong>{checkedUser.company}</strong>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col sm='1' md='1' lg='1'>
                          <PersonCircle size={35} />
                        </Col>
                        <Col>
                          <strong style={{ color: 'red' }}>Debe verificar el cliente</strong>
                        </Col>
                      </>
                    )}
                  </Row>
                </Card.Body>
              </Card>
              <Row className='pt-4'>
                <Col sm='6' md='6' lg='6'>
                  <h5>Fecha de compra</h5>
                  <DatePicker
                    required
                    dateFormat="yyyy/MM/dd"
                    placeholderText='Fecha de compra'
                    className='form-control'
                    renderCustomHeader={({
                      date,
                      changeYear,
                      changeMonth,
                    }) => (
                      <div
                        style={{
                          margin: 10,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <select
                          value={date.getFullYear()}
                          onChange={({ target: { value } }) => changeYear(value)}
                        >
                          {years.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>

                        <select
                          value={months[date.getMonth()]}
                          onChange={({ target: { value } }) =>
                            changeMonth(months.indexOf(value))
                          }
                        >
                          {months.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    selected={formData.purchase_date}
                    onChange={(date) => setFormData({ ...formData, purchase_date: new Date(date.toISOString()) })}
                  />
                </Col>
              </Row>
              <h5 className='pt-4 pb-2'>Productos asociados</h5>
              {checkedUser ? (
                <>
                  {loadingProducts ? (
                    <p>Cargando productos...</p>
                  ) : errorProducts ? (
                    <p>Error: {errorProducts}</p>
                  ) : products.length === 0 ? (
                    <p>No se encontraron productos.</p>
                  ) : (
                    <Row>
                      <Col sm='7' md='7' lg='7'>
                        <ListGroup>
                          {selectedProducts.length === 0 ? (
                            <p style={{ color: 'red' }}>Debe seleccionar productos</p>
                          ) : (
                            selectedProducts.map(product => (
                              <ListGroup.Item key={product.uuid} className="d-flex justify-content-between align-items-center mb-3">
                                <Row className="d-flex align-items-center" style={{ width: '100%' }}>
                                  <Col sm='5' md='5' lg='5'>
                                    <Card.Text style={{ marginBottom: '0rem' }}><strong>{product.name}</strong></Card.Text>
                                  </Col>
                                  <Col sm='4' md='4' lg='4'>
                                    <Card.Text>Código: {product.code}</Card.Text>
                                  </Col>
                                  <Col sm='3' md='3' lg='3'>
                                    <Form.Control
                                      type="number"
                                      value={product.quantity}
                                      onChange={(e) => handleQuantityChange(e, product.uuid)}
                                      min={1}
                                      style={{ height: '40px' }}
                                    />
                                  </Col>
                                </Row>
                                <div className="d-flex">
                                  <Button variant="danger" size="md" onClick={() => handleRemoveProduct(product)}>x</Button>
                                </div>
                              </ListGroup.Item>
                            ))
                          )}
                        </ListGroup>
                      </Col>
                      <Col sm='5' md='5' lg='5'>
                        <Select
                          options={options}
                          onChange={handleChange}
                          placeholder="Agregar productos asociados"
                          className="form-control mb-4"
                        />
                      </Col>
                    </Row>
                  )}
                </>
              ) : (
                <Col>
                  <strong style={{ color: 'red' }}>Debe verificar el cliente</strong>
                </Col>
              )}

            </div>
            <Modal.Footer>
              <Button variant="primary" type='submit' disabled={isFormEmpty}>Crear pedido</Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>

      <Modal show={!!verificationMessage} onHide={closeResponseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{verificationMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeResponseModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={!!successMessage || !!errorMessage} onHide={handleCloseMessage}>
        <Modal.Header closeButton>
          <Modal.Title>{errorMessage ? 'Error en la creación' : 'Creación exitosa'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {successMessage || errorMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseMessage}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default NewOrderModal;
