import { createSlice } from '@reduxjs/toolkit';
import { changeOrderStatus, createOrder, deleteOrderById, getOrderById, getOrdersWithFilters } from '../actions/ordersThunks';
import { withAuthentication } from '../middlewares';

const initialState = {
  data: [],
  order: null,
  loading: false,
  error: null,
  successMessage: '',
  errorMessage: '',
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    editCloseMessage(state) {
      state.successMessage = '';
      state.errorMessage = '';
    },
    //Traer todos los ordenes
    fetchOrdersStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchOrdersSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
    fetchOrdersFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    //Crear orden
    createNewOrderStart(state) {
      state.loading = true;
      state.error = null;
    },
    createNewOrderSuccess(state, action) {
      state.loading = false;
      state.order = action.payload;
      state.successMessage = 'Órden creada exitosamente';
      state.errorMessage = '';
    },
    createNewOrderFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.errorMessage = 'Error al crear órden';
      state.successMessage = '';
    },
    //Borrar una órden
    deleteOrderStart(state) {
      state.loading = true;
      state.error = null;
    },
    deleteOrderSuccess(state, action) {
      state.loading = false;
      state.error = null;
      //Borrar órden del estado
      const orderuuid = action.payload;
      const deletedOrderIndex = state.data.findIndex(order => order.uuid === orderuuid);

      if (deletedOrderIndex !== -1) {
        state.data.splice(deletedOrderIndex, 1);
        if (state.order && state.order.uuid === orderuuid) {
          state.order = null;
        }
      }
    },
    deleteOrderFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    //Traer una órden para edición/detalle
    fetchOrderStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchOrderSuccess(state, action) {
      state.loading = false;
      state.order = action.payload;
    },
    fetchOrderFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    clearOrder(state) {
      state.order = null;
    },
    //Editar una órden
    editOrderStatusStart(state) {
      state.loading = true;
      state.error = null;
    },
    editOrderStatusSuccess(state, action) {
      state.loading = false;
      const { uuid, status_name, status_color } = action.payload;

      if (Array.isArray(state.data.orders)) {
        const editedOrderIndex = state.data.orders.findIndex(order => order.uuid === uuid);

        if (editedOrderIndex !== -1) {
          state.data.orders[editedOrderIndex].status_name = status_name;
          state.data.orders[editedOrderIndex].status_color = status_color;
        } else {
          console.error(`Error: No se encontró la orden con uuid: ${uuid}`);
        }
      } else {
        console.error('Error: state.data.orders no es un array');
      }

      state.errorMessage = '';
    },
    editOrderStatusFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.errorMessage = 'Error en la operación';
      state.successMessage = '';
    },
  },
});

export const fetchOrders = withAuthentication((authToken, ordersFilters) => async (dispatch, getState) => {
  dispatch(fetchOrdersStart());
  try {
    const orders = await getOrdersWithFilters(authToken, ordersFilters);
    dispatch(fetchOrdersSuccess(orders));
  } catch (error) {
    dispatch(fetchOrdersFailure(error.message));
  }
});

export const createNewOrder = withAuthentication((authToken, newOrder) => async (dispatch, getState) => {
  dispatch(createNewOrderStart());
  try {
    const order = await createOrder(authToken, newOrder);
    dispatch(createNewOrderSuccess(order));
  } catch (error) {
    dispatch(createNewOrderFailure(error.message));
  }
});

export const fetchOrderById = withAuthentication((authToken, orderuuid) => async (dispatch, getState) => {
  dispatch(fetchOrderStart());
  try {
    const order = await getOrderById(authToken, orderuuid);
    dispatch(fetchOrderSuccess(order));
  } catch (error) {
    dispatch(fetchOrderFailure(error.message));
  }
});

export const deleteOrder = withAuthentication((authToken, orderuuid) => async (dispatch, getState) => {
  dispatch(deleteOrderStart());
  try {
    await deleteOrderById(authToken, orderuuid)
    dispatch(deleteOrderSuccess(orderuuid));
  } catch (error) {
    dispatch(deleteOrderFailure(error.message));
  }
});

export const editOrderStatus = withAuthentication((authToken, orderuuid, newStatus) => async (dispatch, getState) => {
  dispatch(editOrderStatusStart());
  try {
    const order = await changeOrderStatus(authToken, orderuuid, newStatus)
    dispatch(editOrderStatusSuccess(order));
  } catch (error) {
    dispatch(editOrderStatusFailure(error.message));
  }
});

export const {
  editCloseMessage,
  fetchOrdersStart,
  fetchOrdersSuccess,
  fetchOrdersFailure,
  createNewOrderStart,
  createNewOrderSuccess,
  createNewOrderFailure,
  fetchOrderStart,
  fetchOrderSuccess,
  fetchOrderFailure,
  deleteOrderStart,
  deleteOrderSuccess,
  deleteOrderFailure,
  editOrderStatusStart,
  editOrderStatusSuccess,
  editOrderStatusFailure,
  clearOrder
} = ordersSlice.actions;

export const selectOrders = (state) => state.orders.data;
export const selectOrdersLoading = (state) => state.orders.loading;
export const selectOrdersError = (state) => state.orders.error;
export const selectOrder = (state) => state.orders.order;
export const selectSuccessMessage = (state) => state.orders.successMessage;
export const selectErrorMessage = (state) => state.orders.errorMessage;

export default ordersSlice.reducer;
