import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ButtonGroup, Card, Col, Container, ListGroup, Row } from 'react-bootstrap';
import SearchBar from '../../components/Searchbar';
import { PencilSquare, Trash } from 'react-bootstrap-icons';
import NewEstablishmentModal from './NewEstablishmentModal';
import DeleteEstablishmentModal from './DeleteEstablishmentModal';
import EditEstablishmentModal from './EditEstablishmentModal';
import { clearEstablishment, fetchEstablishmentById, fetchEstablishments, selectEstablishment, selectEstablishments, selectEstablishmentsError, selectEstablishmentsLoading } from '../../redux/reducers/establishmentsReducer';
import { selectLoggedUserProfile } from '../../redux/reducers/authReducer';

const Establishments = () => {
  const dispatch = useDispatch();
  const loading = useSelector(selectEstablishmentsLoading);
  const establishments = useSelector(selectEstablishments);
  const establishment = useSelector(selectEstablishment)
  const error = useSelector(selectEstablishmentsError);
  const profilesAuth = useSelector(selectLoggedUserProfile);

  const hasManageEstablishmentsAccess = profilesAuth?.some(profile =>
    profile.accesses?.some(access => access.name === 'manage_establishments')
  );

  useEffect(() => {
    dispatch(fetchEstablishments(null));
  }, [dispatch]);

  //Popups
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showNewEstablishmentModal, setShowNewEstablishmentModal] = useState(false);

  const handleEditOpen = (establishmentuuid) => {
    dispatch(fetchEstablishmentById(establishmentuuid));
    setShowEditModal(true);
  };

  const handleEditClose = () => {
    dispatch(clearEstablishment());
    setShowEditModal(false);
    dispatch(fetchEstablishments(''));
  };

  const handleDeleteOpen = (establishmentuuid) => {
    dispatch(fetchEstablishmentById(establishmentuuid));
    setShowDeleteModal(true);
  };

  const handleDeleteClose = (establishmentuuid) => {
    dispatch(clearEstablishment())
    setShowDeleteModal(false);
    dispatch(fetchEstablishments(''));
  };

  const handleNewEstablishmentOpen = () => {
    setShowNewEstablishmentModal(true);
  };

  const handleNewEstablishmentClose = () => {
    setShowNewEstablishmentModal(false);
    dispatch(fetchEstablishments(''));
  };

  //Searchbar
  const handleSearch = (text) => {
    dispatch(fetchEstablishments(text));
  };

  return (
    <Container className='pt-4 pb-4'>
      <Row>
        <Col>
          <Container className='pb-4 align-items-center'>
            <Row className="align-items-center">
              <Col sm='4' md='4' lg='4'>
                <h1 style={{ marginBottom: 0 }}>Establecimientos</h1>
              </Col>
              <Col sm='6' md='6' lg='6'>
                <SearchBar onSearch={handleSearch} parameterName={"nombre"} />
              </Col>
              {hasManageEstablishmentsAccess
                && (
                  <Col sm='2' md='2' lg='2'>
                    <Button variant='primary' style={{ marginTop: 0, padding: '0.8rem 1rem', marginLeft: '10px' }} onClick={handleNewEstablishmentOpen}>Nuevo establecimiento</Button>
                  </Col>
                )}
            </Row>
          </Container>
          {loading ? (
            <p>Cargando...</p>
          ) : error ? (
            <p>Error: {error}</p>
          ) : establishments.length === 0 ? (
            <p>No se encontraron establecimientos.</p>
          ) : (
            <ListGroup>
              {establishments.map(establishment => (
                <ListGroup.Item key={establishment.uuid} className="d-flex justify-content-between align-items-center mb-3">
                  <div>
                    <Card.Text style={{ marginBottom: '0.75rem' }}><strong>{establishment.name}</strong></Card.Text>
                    <Card.Text>Código: {establishment.code}</Card.Text>
                  </div>
                  <div className="d-flex">
                    {hasManageEstablishmentsAccess
                      && (
                        <ButtonGroup>
                          <Button variant="danger" style={{ backgroundColor: '#1A3B85', borderColor: '#1A3B85' }} size="lg" onClick={() => handleEditOpen(establishment.uuid)}><PencilSquare /></Button>
                          <Button variant="danger" size="lg" onClick={() => handleDeleteOpen(establishment.uuid)}><Trash /></Button>
                        </ButtonGroup>
                      )}
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}

          {/*POPUPS*/}
          <NewEstablishmentModal showNewEstablishmentModal={showNewEstablishmentModal} handleClose={handleNewEstablishmentClose} />
          <EditEstablishmentModal showEditModal={showEditModal} handleClose={handleEditClose} establishment={establishment} />
          <DeleteEstablishmentModal showDeleteModal={showDeleteModal} handleClose={handleDeleteClose} establishment={establishment} />
        </Col>
      </Row>
    </Container >
  )
}

export default Establishments