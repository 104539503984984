import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { editCloseMessage, editCompany, selectErrorMessage, selectSuccessMessage } from '../../redux/reducers/companiesReducer';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

const EditCompanyModal = ({ showEditModal, handleClose, company }) => {
    // Llamadas a API y Redux
    const dispatch = useDispatch();

    //Nombre
    const [name, setName] = useState('');

    //Cambios de formulario
    const [initialName, setInitialName] = useState();
    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        if (company) {
            setName(company.name);
            setInitialName(company.name)
        }
    }, [company]);

    const handleCloseMessage = () => {
        handleClose();
        dispatch(editCloseMessage());
    }

    //Mensaje de exito o error
    const successMessage = useSelector(selectSuccessMessage);
    const errorMessage = useSelector(selectErrorMessage);

    //Cambio de formulario
    useEffect(() => {
        if (initialName && name) {
            setIsChanged(initialName !== name);
        }
    }, [initialName, name]);

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleSubmit = () => {
        dispatch(editCompany(company.uuid, name));
    };


    return (
        <div>
            <Modal show={showEditModal} onHide={handleClose} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Editar compañía</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ marginLeft: '8px', marginRight: '8px' }}>
                    <form style={{ display: 'contents' }}>
                        <div>
                            {company && (
                                <>
                                    <Row className='pb-4'>
                                        <Col sm='12' md='12' lg='12'>
                                            <Form.Group>
                                                <Form.Label style={{ fontWeight: 'bold' }}>Nombre</Form.Label>
                                                <Form.Control
                                                    name='name'
                                                    type="text"
                                                    placeholder="Nombre de ubicación..."
                                                    value={name}
                                                    onChange={handleNameChange}
                                                    style={{ marginTop: 0, height: '100%' }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </>
                            )}

                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    {!isChanged && (
                        <p className="form-text text-muted">
                            Debe realizar cambios para guardar
                        </p>
                    )}
                    <Button variant="primary" style={{ marginTop: '0px' }} onClick={handleSubmit} disabled={!isChanged}>Guardar cambios</Button>
                </Modal.Footer>
                <Modal show={!!successMessage || !!errorMessage} onHide={handleCloseMessage}>
                    <Modal.Header closeButton>
                        <Modal.Title>{errorMessage ? 'Error en la creación' : 'Creación exitosa'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {successMessage || errorMessage}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseMessage}>Cerrar</Button>
                    </Modal.Footer>
                </Modal>
            </Modal >


        </div>
    )
}

export default EditCompanyModal