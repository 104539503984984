import { createSlice } from '@reduxjs/toolkit';
import { getModules } from '../actions/modulesThunks';
import { withAuthentication } from '../middlewares';

const initialState = {
    data: [],
    loading: false,
    error: null,
};

const modulesSlice = createSlice({
    name: 'modules',
    initialState,
    reducers: {
        fetchModulesStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchModulesSuccess(state, action) {
            state.loading = false;
            state.data = action.payload;
        },
        fetchModulesFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        clearModules(state) {
            state.data = null;
        }
    },
});

export const fetchModules = withAuthentication((authToken) => async (dispatch, getState) => {
    dispatch(fetchModulesStart());
    try {
        const modules = await getModules(authToken);
        dispatch(fetchModulesSuccess(modules));
    } catch (e) {
        dispatch(fetchModulesFailure(e.message));
    }
})

export const {
    fetchModulesStart,
    fetchModulesSuccess,
    fetchModulesFailure,
    clearModules
} = modulesSlice.actions;

export const selectModules = (state) => state.modules.data;
export const selectModulesLoading = (state) => state.modules.loading;
export const selectModulesError = (state) => state.modules.error;

export default modulesSlice.reducer;