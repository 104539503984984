import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col, ListGroup, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { editCloseMessage, editUser, selectErrorMessage, selectSuccessMessage } from '../../redux/reducers/usersReducer';
import { fetchProfiles, selectProfiles } from '../../redux/reducers/profileReducer';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.module.css"
import { fetchCompanies, selectCompanies } from '../../redux/reducers/companiesReducer';
import { fetchEstablishments, selectEstablishments, selectEstablishmentsError, selectEstablishmentsLoading } from '../../redux/reducers/establishmentsReducer';
import { selectLoggedUserProfile } from '../../redux/reducers/authReducer';

const EditUserModal = ({ showEditModal, handleClose, user }) => {
  // Llamadas a API y Redux
  const dispatch = useDispatch();
  const profilesAuth = useSelector(selectLoggedUserProfile);

  //Mensaje de exito o error
  const successMessage = useSelector(selectSuccessMessage);
  const errorMessage = useSelector(selectErrorMessage);

  const hasAssignProfilesAccess = profilesAuth?.some(profile =>
    profile.accesses?.some(access => access.name === 'assign_profiles')
  );

  //Formulario
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    phone: '',
    username: '',
    dni: '',
    email: '',
    uuid_company: '',
    position: '',
    profiles: [],
    establishments: []
  });

  useEffect(() => {
    if (user) {
      if (user.employee) {
        setFormData({
          first_name: user.employee.first_name,
          last_name: user.employee.last_name,
          phone: user.employee.phone,
          username: user.user?.username || '',
          position: user.employee.position,
          profiles: user.user?.profiles?.map(profile => profile.uuid) || [],
          establishments: user.user.establishments || []
        });
        setInitialFormData({
          first_name: user.employee.first_name,
          last_name: user.employee.last_name,
          phone: user.employee.phone,
          username: user.user?.username || '',
          position: user.employee.position,
          profiles: user.user?.profiles?.map(profile => profile.uuid) || [],
          establishments: user.user.establishments || []
        });
      } else if (user.client) {
        setFormData({
          first_name: user.client.first_name,
          last_name: user.client.last_name,
          phone: user.client.phone,
          dni: user.client.dni,
          email: user.client.email,
          username: user.user?.username || '',
          uuid_company: user.client.uuid_company,
          birth_date: new Date(user.client.birth_date),
          establishments: user.user.establishments || []
        });
        setInitialFormData({
          first_name: user.client.first_name,
          last_name: user.client.last_name,
          phone: user.client.phone,
          dni: user.client.dni,
          email: user.client.email,
          username: user.user?.username || '',
          uuid_company: user.client.uuid_company,
          birth_date: new Date(user.client.birth_date),
          establishments: user.user.establishments || []
        });
      }
    }
  }, [user]);

  //Compañías
  const companies = useSelector(selectCompanies);
  useEffect(() => {
    dispatch(fetchCompanies(''));
  }, [dispatch]);

  const handleSelectCompany = (event) => {
    const selectedCompanyUUID = event.target.value;
    setFormData(prevFormData => ({
      ...prevFormData,
      uuid_company: selectedCompanyUUID
    }));
  };

  const handleFormChange = (event) => {
    let { name, value } = event.target;
    if (name === 'phone' || name === 'dni') {
      value = value.replace(/\D/g, ''); // Correctly format value
    }
    setFormData({
      ...formData,
      [name]: value
    });
  };

  //Cambios de formulario
  const [initialFormData, setInitialFormData] = useState(null);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    const hasChanges = JSON.stringify(initialFormData) !== JSON.stringify(formData);
    setIsChanged(hasChanges);
  }, [formData, initialFormData]);

  //Perfiles
  const profiles = useSelector(selectProfiles);
  useEffect(() => {
    dispatch(fetchProfiles(null));
  }, [dispatch]);

  const chunkArray = (arr, chunkSize) => {
    const arrCopy = [...arr];
    arrCopy.sort((a, b) => a.name.localeCompare(b.name));

    const chunks = [];
    for (let i = 0; i < arrCopy.length; i += chunkSize) {
      chunks.push(arrCopy.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const chunkedProfiles = chunkArray(profiles, 4);

  const handleProfileCheckboxChange = (event) => {
    const { id, checked } = event.target;
    if (checked) {
      setFormData(prevFormData => ({
        ...prevFormData,
        profiles: [...prevFormData.profiles, id]
      }));
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        profiles: prevFormData.profiles.filter(profileId => profileId !== id)
      }));
    }
  };

  //FECHAS
  function range(start, end, step = 1) {
    const result = [];
    for (let i = start; i < end; i += step) {
      result.push(i);
    }
    return result;
  }
  const years = range(1940, new Date().getFullYear(), 1);
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  //Establishments
  const loadingEstablishments = useSelector(selectEstablishmentsLoading);
  const establishments = useSelector(selectEstablishments);
  const errorEstablishments = useSelector(selectEstablishmentsError);
  useEffect(() => {
    dispatch(fetchEstablishments(''));
  }, [dispatch]);

  const [selectedEstablishments, setSelectedEstablishments] = useState([]);
  const [availableEstablishments, setAvailableEstablishments] = useState([]);

  useEffect(() => {
    if (formData.establishments && establishments) {
      setSelectedEstablishments(establishments.filter(est => formData.establishments.includes(est.uuid)));
      setAvailableEstablishments(establishments.filter(est => !formData.establishments.includes(est.uuid)));
    }
  }, [formData.establishments, establishments]);

  const handleSelectEstablishment = (event) => {
    const selectedEstablishmentId = event.target.value;
    const selectedEstablishment = availableEstablishments.find(establishment => establishment.uuid === selectedEstablishmentId);

    setSelectedEstablishments([...selectedEstablishments, selectedEstablishment]);
    setFormData({
      ...formData,
      establishments: [...formData.establishments, selectedEstablishmentId]
    });

    setAvailableEstablishments(availableEstablishments.filter(establishment => establishment.uuid !== selectedEstablishmentId));
  };

  const handleRemoveEstablishment = (establishmentToRemove) => {
    setAvailableEstablishments([...availableEstablishments, establishmentToRemove]);

    setSelectedEstablishments(selectedEstablishments.filter(establishment => establishment.uuid !== establishmentToRemove.uuid));
    setFormData({
      ...formData,
      establishments: formData.establishments.filter(establishmentId => establishmentId !== establishmentToRemove.uuid)
    });
  };

  //Acciones
  const handleCloseMessage = () => {
    dispatch(editCloseMessage());
    setInitialFormData(null); // Reset initial data
    setFormData({
      first_name: '',
      last_name: '',
      phone: '',
      username: '',
      dni: '',
      email: '',
      uuid_company: '',
      position: '',
      profiles: [],
      establishments: []
    });
    handleClose();
  }

  const handleSubmit = () => {
    if (user?.employee) {
      dispatch(editUser(user.employee.uuid, formData))
    } else if (user?.client) {
      dispatch(editUser(user.client.uuid, formData))
    }
  };

  return (
    <div>
      <Modal show={showEditModal} onHide={handleClose} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Editar usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ marginLeft: '8px', marginRight: '8px' }}>
          <form style={{ display: 'contents' }}>
            <div>
              {user && (
                <>
                  <h4 className='pb-2'>Información personal</h4>
                  <Row className='pb-4'>
                    <Col sm='8' md='8' lg='8'>
                      <Form.Group>
                        <Form.Label style={{ fontWeight: 'bold' }}>Nombre de usuario</Form.Label>
                        <Form.Control
                          name='username'
                          type="text"
                          placeholder="Nombre de usuario..."
                          value={formData.username}
                          onChange={() => { }}
                          style={{ marginTop: 0, height: '100%' }}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col sm='4' md='4' lg='4'>
                      <Form.Group>
                        <Form.Label style={{ fontWeight: 'bold' }}>Teléfono</Form.Label>
                        <Form.Control
                          name='phone'
                          type="text"
                          placeholder="Teléfono..."
                          value={formData.phone}
                          onChange={handleFormChange}
                          style={{ marginTop: 0, height: '100%' }}
                          maxLength={'10'}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='pb-4'>
                    <Col sm='6' md='6' lg='6'>
                      <Form.Group>
                        <Form.Label style={{ fontWeight: 'bold' }}>Nombre</Form.Label>
                        <Form.Control
                          name='first_name'
                          type="text"
                          placeholder="Nombre..."
                          value={formData.first_name}
                          onChange={handleFormChange}
                          style={{ marginTop: 0, height: '100%' }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm='6' md='6' lg='6'>
                      <Form.Group>
                        <Form.Label style={{ fontWeight: 'bold' }}>Apellido</Form.Label>
                        <Form.Control
                          name='last_name'
                          type="text"
                          placeholder="Apellido..."
                          value={formData.last_name}
                          onChange={handleFormChange}
                          style={{ marginTop: 0, height: '100%' }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {user.client ? (
                    <>
                      <h4 className='pb-1'>Cliente</h4>
                      <Row className='pb-4'>
                        <Col sm='3' md='3' lg='3'>
                          <Form.Group>
                            <Form.Label style={{ fontWeight: 'bold' }}>N° DNI</Form.Label>
                            <Form.Control
                              name='dni'
                              type="text"
                              placeholder="DNI..."
                              value={formData.dni}
                              onChange={handleFormChange}
                              maxLength={10}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm='3' md='3' lg='3'>
                          <Form.Group>
                            <Form.Label style={{ fontWeight: 'bold' }}>Email</Form.Label>
                            <Form.Control
                              name='email'
                              type="text"
                              placeholder="Email..."
                              value={formData.email}
                              onChange={handleFormChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm='3' md='3' lg='3'>
                          <Form.Label style={{ fontWeight: 'bold' }}>Fecha de nacimiento</Form.Label>
                          <DatePicker
                            dateFormat="yyyy/MM/dd"
                            placeholderText='Fecha de nacimiento'
                            className='form-control'
                            renderCustomHeader={({
                              date,
                              changeYear,
                              changeMonth,
                            }) => (
                              <div
                                style={{
                                  margin: 10,
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <select
                                  value={date.getFullYear()}
                                  onChange={({ target: { value } }) => changeYear(value)}
                                >
                                  {years.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>

                                <select
                                  value={months[date.getMonth()]}
                                  onChange={({ target: { value } }) =>
                                    changeMonth(months.indexOf(value))
                                  }
                                >
                                  {months.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}
                            selected={formData.birth_date}
                            onChange={(date) => setFormData({ ...formData, birth_date: new Date(date.toISOString()) })}
                          />
                        </Col>
                        <Col m='3' md='3' lg='3'>
                          <Form.Group>
                            <Form.Label style={{ fontWeight: 'bold' }}>Compañía</Form.Label>
                            <Form.Select aria-label="Selecciona una compañía" className='form-control' onChange={handleSelectCompany} value={formData.uuid_company}>
                              <option value="">Selecciona una compañía</option>
                              {companies.map(company => (
                                <option key={company.uuid} value={company.uuid}>{company.name}</option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  ) : user.employee && (
                    <>
                      <h4 className='pb-1'>Colaborador</h4>
                      <Form.Group>
                        <Form.Label style={{ fontWeight: 'bold' }}>Puesto de trabajo</Form.Label>
                        <Form.Control
                          name='position'
                          type="text"
                          placeholder="Puesto de trabajo..."
                          value={formData.position}
                          onChange={handleFormChange}
                          style={{ marginTop: 0, height: '100%' }}
                          className='mb-4'
                        />
                      </Form.Group>
                      {hasAssignProfilesAccess && (
                        <>
                          <h4>Perfiles del usuario</h4>
                          <p className='pb-2'>Seleccione el o los perfiles asociados al usuario</p>
                          {formData.profiles && (
                            chunkedProfiles.map((chunk, index) => (
                              <Row key={index} className="mb-3" style={{ marginLeft: '1rem' }}>
                                {chunk.map(profile => (
                                  <Col key={profile.uuid} md={3} className="pb-3">
                                    <Form.Check
                                      type="checkbox"
                                      id={profile.uuid}
                                      label={profile.name}
                                      onChange={handleProfileCheckboxChange}
                                      checked={formData.profiles.includes(profile.uuid)}
                                    />
                                  </Col>
                                ))}
                              </Row>
                            )
                            ))}
                        </>
                      )}

                    </>
                  )
                  }

                </>
              )}
              <h4 className='pb-1'>Establecimientos</h4>
              {loadingEstablishments ? (
                <p>Cargando establecimientos...</p>
              ) : errorEstablishments ? (
                <p>Error: {errorEstablishments}</p>
              ) : establishments.length === 0 ? (
                <p>No se encontraron establecimientos.</p>
              ) : (
                <Row>
                  <Col m='7' md='7' lg='7'>
                    <ListGroup>
                      {selectedEstablishments.length === 0 ? (
                        <p>Sin establecimientos seleccionados aún</p>
                      ) : (selectedEstablishments.map(establishment => (
                        <ListGroup.Item key={establishment.uuid} className="d-flex justify-content-between align-items-center mb-3">
                          <div>
                            <Card.Text style={{ marginBottom: '0rem' }}><strong>{establishment.name}</strong></Card.Text>
                            <Card.Text>Código: {establishment.code}</Card.Text>
                          </div>
                          <div className="d-flex">
                            <Button variant="danger" size="md" onClick={() => handleRemoveEstablishment(establishment)}>x</Button>
                          </div>
                        </ListGroup.Item>
                      )))}
                    </ListGroup>
                  </Col>
                  <Col m='5' md='5' lg='5' className='pb-3'>
                    <Form.Select aria-label="Agregar establecimientos asociados" className='form-control' onChange={handleSelectEstablishment}>
                      <option value="">Agregar establecimientos asociados</option>
                      {availableEstablishments.map(establishment => (
                        <option key={establishment.uuid} value={establishment.uuid}>{establishment.name}</option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>
              )
              }
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          {!isChanged && (
            <p className="form-text text-muted">
              Debe realizar cambios para guardar
            </p>
          )}
          <Button variant="primary" style={{ marginTop: '0px' }} onClick={handleSubmit} disabled={!isChanged}>Guardar cambios</Button>
        </Modal.Footer>
        <Modal show={!!successMessage || !!errorMessage} onHide={handleCloseMessage}>
          <Modal.Header closeButton>
            <Modal.Title>{errorMessage ? 'Error en la modificación' : 'Modificación exitosa'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {successMessage || errorMessage}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseMessage}>Cerrar</Button>
          </Modal.Footer>
        </Modal>
      </Modal >
    </div>
  );
}

export default EditUserModal;
