import React from 'react'
import { Badge, Dropdown } from 'react-bootstrap'
import { ChevronDown } from 'react-bootstrap-icons'
import '../pages/styles/Orders.css'

const OrderStatusDropdown = ({ orderStatuses, currentStatus, handleChangeStatus, orderUuid }) => {
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <button
            type="button"
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            style={{
                background: 'none',
                border: 'none',
                color: 'inherit',
                textDecoration: 'none',
                padding: 0,
                cursor: 'pointer'
            }}
        >
            {children}
        </button>
    ));

    return (
        <Dropdown align="end">
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                <ChevronDown className='ms-2' color='black' />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {orderStatuses ? (
                    orderStatuses
                        .filter(orderStatus => orderStatus.name !== currentStatus)
                        .map((orderStatus) => (
                            <Dropdown.Item
                                key={orderStatus.uuid}
                                style={{ '--status-color': orderStatus.color }}
                                onClick={() => handleChangeStatus(orderUuid, orderStatus)}
                            >
                                <Badge pill className='custom-badge'>
                                    {orderStatus.name}
                                </Badge>
                            </Dropdown.Item>
                        ))
                ) : (
                    <p>a</p>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default OrderStatusDropdown;
