import React, { useEffect, useState } from 'react';
import { Container, Nav, Row, Col, Card, Button } from 'react-bootstrap';
import { Outlet, useNavigate, Link, useLocation } from 'react-router-dom';
import './styles/Layout.css';
import img_logo from '../imgs/LOGOTIPO_BLANCO.png';
import { useDispatch, useSelector } from 'react-redux';
import { clearUserLogged, selectLoggedUserProfile, selectLoggedUserUserName, selectToken } from '../redux/reducers/authReducer';
import { PersonCircle } from 'react-bootstrap-icons';
import ChangePasswordModal from '../pages/Profile/ChangePasswordModal';

const Layout = () => {
  const username = useSelector(selectLoggedUserUserName);
  const profiles = useSelector(selectLoggedUserProfile);
  const location = useLocation();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const navigate = useNavigate();
  const [showChangePassword, setShowChangePassword] = useState(false);

  useEffect(() => {
    if (!token) {
      navigate('/');
    }
  }, [token, navigate]);

  const moduleToPath = {
    'Pedidos': '/home/orders',
    'Perfiles': '/home/profiles',
    'Usuarios': '/home/users',
    'Productos': '/home/products',
    'Establecimientos': '/home/establishments',
    'Ubicaciones': '/home/locations',
    'Compañías': '/home/companies',
    'Certificaciones': '/home/certifications'
  };

  const allowedLinks = profiles
    ? profiles.flatMap(profile => profile.accesses.map(access => access.name))
    : [];

  useEffect(() => {
    const currentPathAccess = Object.values(moduleToPath).find(path => location.pathname.startsWith(path));
    if (currentPathAccess && !allowedLinks.includes(`view_${currentPathAccess.slice(6).toLowerCase()}`)) {
      navigate('/home');
    }
  }, [allowedLinks, location.pathname, navigate, moduleToPath]);

  return (
    <Container fluid className="d-flex flex-column vh-100">
      <Row className="flex-grow-1">
        <Col xs={2} id="sidebar-wrapper" className="sidebar d-flex flex-column overflow-y-auto max-h-screen">
          <div className='pt-3'>
            <img src={img_logo} alt='Logo Saigro' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </div>
          <hr style={{ color: '#f8f9fa', backgroundColor: '#122C6B', height: 3 }} />
          <Nav className="col-md-12 d-none d-md-block flex-column">
            <div className="sidebar-sticky"></div>
            <Nav.Item>
              <Card
                className={location.pathname === '/home' ? 'custom-card-active' : 'custom-card'}
              >
                <Nav.Link
                  className={location.pathname === '/home' ? 'custom-link-active' : 'custom-link'}
                  as={Link} to="/home">Home</Nav.Link>
              </Card>
            </Nav.Item>
            {Object.keys(moduleToPath).map((moduleName, index) => (
              allowedLinks.includes(`view_${moduleToPath[moduleName].slice(6).toLowerCase()}`) && (
                <Nav.Item key={index}>
                  <Card className={location.pathname === moduleToPath[moduleName] ? 'custom-card-active' : 'custom-card'}>
                    <Nav.Link
                      className={location.pathname === moduleToPath[moduleName] ? 'custom-link-active' : 'custom-link'}
                      as={Link} to={moduleToPath[moduleName]}>{moduleName}</Nav.Link>
                  </Card>
                </Nav.Item>
              )
            ))}
          </Nav>
          <Container className="mt-auto pb-4">
            <Row className='align-items-center'>
              <Col sm='3' md='3' lg='3'>
                <PersonCircle size={35} className="me-3" />
              </Col>
              <Col sm='6' md='6' lg='6'>
                <div>
                  <Card.Text className="mb-2"><strong>{username}</strong></Card.Text>
                </div>
              </Col>
            </Row>
            <Button size='sm' variant="outline-light" className="mb-2 mt-2 w-100" onClick={() => setShowChangePassword(true)}>
              Cambiar contraseña
            </Button>
            <Button size='sm' variant="outline-light" className="mb-2 mt-2 w-100" onClick={() => dispatch(clearUserLogged())}>
              Cerrar sesión
            </Button>
          </Container>
        </Col>
        <Col xs={10} id="page-content-wrapper" className='pt-2 overflow-y-auto max-h-screen'>
          <Outlet />
        </Col>
      </Row>
      <ChangePasswordModal
        show={showChangePassword}
        handleClose={() => setShowChangePassword(false)}
      />
    </Container>
  );
};

export default Layout;
