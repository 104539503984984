import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  establishments: [],
  profiles: [],
  uuid: '',
  auth_token: '',
  username: ''
};

const authSlice = createSlice({
  name: 'userLogged',
  initialState,
  reducers: {
    setUserLogged: (state, action) => {
      return { ...state, ...action.payload };
    },
    clearUserLogged: () => initialState,
  },
});

export const { setUserLogged, clearUserLogged } = authSlice.actions;

export const selectLoggedUserUserName = (state) => state.auth.username;
export const selectLoggedUserProfile = (state) => state.auth.profiles;
export const selectToken = (state) => state.auth.auth_token;

export default authSlice.reducer;