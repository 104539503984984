// Funcion previa, deprecada
// export const getAllProfiles = async (token) => {
//     try {
//         const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/profiles`, {
//             method: 'GET',
//             headers: {
//                 'Content-Type': 'application/json',
//                 //'Authorization': 'Bearer ' + token
//             },
//         });

//         if (!response.ok) {
//             return {
//                 data: response.data,
//                 status: response.status,
//             };
//         }

//         const data = await response.json();

//         return data;
//     } catch (error) {
//         console.error('Login error:', error);
//     }
// };

export const getProfilesWithSearch = async (token, profileName) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/profiles/get`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                name: profileName
            })
        }
        );

        if (!response.ok) {
            throw new Error(`Failed operation: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const getProfileById = async (token, profileUuid) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/profiles/${profileUuid}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        );

        if (!response.ok) {
            throw new Error(`Failed operation: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const deleteProfileById = async (token, profileUuid) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/profiles/${profileUuid}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        );

        if (!response.ok) {
            throw new Error(`Failed operation: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const getProfileAcceses = async (token, profileUuid) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/profiles/${profileUuid}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        );

        if (!response.ok) {
            throw new Error(`Failed operation: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const editProfileById = async (token, profileUuid, editedProfile) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/profiles/${profileUuid}/set_accesses`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(editedProfile)
        }
        );

        if (!response.ok) {
            throw new Error(`Failed operation: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const createProfile = async (token, newProfile) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/profiles`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: newProfile
        }
        );

        if (!response.ok) {
            throw new Error(`Failed operation: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};