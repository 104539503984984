import React, { useState } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectToken } from '../../redux/reducers/authReducer';  // Importa el selector para obtener el token desde Redux

const ChangePasswordModal = ({ show, handleClose }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const token = useSelector(selectToken);  // Obtén el token desde Redux

  const handleChangePassword = async () => {
    setErrorMessage('');
    setSuccessMessage('');

    if (password !== confirmPassword) {
      setErrorMessage('Las contraseñas no coinciden.');
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/change_password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`  // Usa el token desde Redux
        },
        body: JSON.stringify({
          current_password: oldPassword,
          new_password: password
        })
      });

      if (response.ok) {
        setSuccessMessage('Contraseña cambiada exitosamente.');
        setOldPassword('');
        setPassword('');
        setConfirmPassword('');
        setTimeout(() => {
          handleClose();
        }, 2000); // Cerrar el modal después de 2 segundos
      } else {
        const data = await response.json();
        setErrorMessage(data.message || 'Error al cambiar la contraseña.');
      }
    } catch (error) {
      console.error('Error al cambiar la contraseña:', error);
      setErrorMessage('Error de red al cambiar la contraseña.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Cambiar contraseña</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
        {successMessage && <Alert variant="success">{successMessage}</Alert>}

        <Form.Group controlId="oldPassword" className="mt-3">
          <Form.Label>Contraseña anterior</Form.Label>
          <Form.Control
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="newPassword" className="mt-3">
          <Form.Label>Nueva contraseña</Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="confirmNewPassword" className="mt-3">
          <Form.Label>Confirmar nueva contraseña</Form.Label>
          <Form.Control
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={handleClose}
          disabled={isLoading}
          className='bg-red-500 hover:bg-red-700 text-white'
        >
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleChangePassword} disabled={isLoading}>
          {isLoading ? 'Cambiando...' : 'Cambiar contraseña'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangePasswordModal;
