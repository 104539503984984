import { createSlice } from '@reduxjs/toolkit';
import { createCompany, deleteCompanyById, editCompanyById, getCompaniesWithSearch, getCompanyById } from '../actions/companiesThunks';
import { withAuthentication } from '../middlewares';

const initialState = {
    data: [],
    company: null,
    loading: false,
    error: null,
    successMessage: '',
    errorMessage: '',
};

const companiesSlice = createSlice({
    name: 'companies',
    initialState,
    reducers: {
        editCloseMessage(state) {
            state.successMessage = '';
            state.errorMessage = '';
        },
        //Traer todos los compañias
        fetchCompaniesStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchCompaniesSuccess(state, action) {
            state.loading = false;
            state.data = action.payload;
        },
        fetchCompaniesFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        //Crear compañia
        createNewCompanyStart(state) {
            state.loading = true;
            state.error = null;
        },
        createNewCompanySuccess(state, action) {
            state.loading = false;
            state.company = action.payload;
            state.successMessage = 'Compañía creada exitosamente';
            state.errorMessage = '';
        },
        createNewCompanyFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.errorMessage = 'Error al crear compañía';
            state.successMessage = '';
        },
        //Borrar una compañia
        deleteCompanyStart(state) {
            state.loading = true;
            state.error = null;
        },
        deleteCompanySuccess(state, action) {
            state.loading = false;
            state.error = null;
            //Borrar ubicación del estado
            const companyuuid = action.payload;
            const deletedCompanyIndex = state.data.findIndex(company => company.uuid === companyuuid);

            if (deletedCompanyIndex !== -1) {
                state.data.splice(deletedCompanyIndex, 1);
                if (state.company && state.company.uuid === companyuuid) {
                    state.company = null;
                }
            }
        },
        deleteCompanyFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        //Traer una compañia para edición/detalle
        fetchCompanyStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchCompanySuccess(state, action) {
            state.loading = false;
            state.company = action.payload;
        },
        fetchCompanyFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        clearCompany(state) {
            state.company = null;
        },
        //Editar una ubicación
        editCompanyStart(state) {
            state.loading = true;
            state.error = null;
        },
        editCompanySuccess(state, action) {
            state.loading = false;
            state.company = action.payload;
            state.successMessage = 'Operación exitosa';
            state.errorMessage = '';
        },
        editCompanyFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.errorMessage = 'Error en la operación';
            state.successMessage = '';
        },
    },
});

export const fetchCompanies = withAuthentication((authToken, companyName) => async (dispatch, getState) => {
    dispatch(fetchCompaniesStart());
    try {
        const companies = await getCompaniesWithSearch(authToken, companyName);
        dispatch(fetchCompaniesSuccess(companies));
    } catch (error) {
        dispatch(fetchCompaniesFailure(error.message));
    }
});

export const createNewCompany = withAuthentication((authToken, companyName) => async (dispatch, getState) => {
    dispatch(createNewCompanyStart());
    try {
        const company = await createCompany(authToken, companyName);
        dispatch(createNewCompanySuccess(company));
    } catch (error) {
        dispatch(createNewCompanyFailure(error.message));
    }
});

export const fetchCompanyById = withAuthentication((authToken, companyuuid) => async (dispatch, getState) => {
    dispatch(fetchCompanyStart());
    try {
        const company = await getCompanyById(authToken, companyuuid);
        dispatch(fetchCompanySuccess(company));
    } catch (error) {
        dispatch(fetchCompanyFailure(error.message));
    }
});

export const deleteCompany = withAuthentication((authToken, companyuuid) => async (dispatch, getState) => {
    dispatch(deleteCompanyStart());
    try {
        await deleteCompanyById(authToken, companyuuid)
        dispatch(deleteCompanySuccess(companyuuid));
    } catch (error) {
        dispatch(deleteCompanyFailure(error.message));
    }
});

export const editCompany = withAuthentication((authToken, companyuuid, companyName) => async (dispatch, getState) => {
    dispatch(editCompanyStart());
    try {
        const company = await editCompanyById(authToken, companyuuid, companyName)
        dispatch(editCompanySuccess(company));
    } catch (error) {
        dispatch(editCompanyFailure(error.message));
    }
});

export const {
    editCloseMessage,
    fetchCompaniesStart,
    fetchCompaniesSuccess,
    fetchCompaniesFailure,
    createNewCompanyStart,
    createNewCompanySuccess,
    createNewCompanyFailure,
    fetchCompanyStart,
    fetchCompanySuccess,
    fetchCompanyFailure,
    deleteCompanyStart,
    deleteCompanySuccess,
    deleteCompanyFailure,
    editCompanyStart,
    editCompanySuccess,
    editCompanyFailure,
    clearCompany
} = companiesSlice.actions;

export const selectCompanies = (state) => state.companies.data;
export const selectCompaniesLoading = (state) => state.companies.loading;
export const selectCompaniesError = (state) => state.companies.error;
export const selectCompany = (state) => state.companies.company;
export const selectSuccessMessage = (state) => state.companies.successMessage;
export const selectErrorMessage = (state) => state.companies.errorMessage;

export default companiesSlice.reducer;
