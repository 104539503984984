import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ButtonGroup, Card, Col, Container, ListGroup, Row } from 'react-bootstrap';
import SearchBar from '../../components/Searchbar';
import { PencilSquare, Trash } from 'react-bootstrap-icons';
import NewCompanyModal from './NewCompanyModal';
import DeleteCompanyModal from './DeleteCompanyModal';
import EditCompanyModal from './EditCompanyModal';
import { clearCompany, fetchCompanies, fetchCompanyById, selectCompanies, selectCompaniesError, selectCompaniesLoading, selectCompany } from '../../redux/reducers/companiesReducer';
import { selectLoggedUserProfile } from '../../redux/reducers/authReducer';

const Companies = () => {
  const dispatch = useDispatch();
  const loading = useSelector(selectCompaniesLoading);
  const companies = useSelector(selectCompanies);
  const company = useSelector(selectCompany)
  const error = useSelector(selectCompaniesError);
  const profilesAuth = useSelector(selectLoggedUserProfile);

  const hasManageCompaniesAccess = profilesAuth?.some(profile =>
    profile.accesses?.some(access => access.name === 'manage_companies')
  );

  useEffect(() => {
    dispatch(fetchCompanies(null));
  }, [dispatch]);

  //Popups
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showNewCompanyModal, setShowNewCompanyModal] = useState(false);

  const handleEditOpen = (companyuuid) => {
    dispatch(fetchCompanyById(companyuuid));
    setShowEditModal(true);
  };

  const handleEditClose = () => {
    dispatch(clearCompany());
    setShowEditModal(false);
    dispatch(fetchCompanies(''));
  };

  const handleDeleteOpen = (companyuuid) => {
    dispatch(fetchCompanyById(companyuuid));
    setShowDeleteModal(true);
  };

  const handleDeleteClose = (companyuuid) => {
    dispatch(clearCompany())
    setShowDeleteModal(false);
    dispatch(fetchCompanies(''));
  };

  const handleNewLocationOpen = () => {
    setShowNewCompanyModal(true);
  };

  const handleNewLocationClose = () => {
    setShowNewCompanyModal(false);
    dispatch(fetchCompanies(''));
  };

  //Searchbar
  const handleSearch = (text) => {
    dispatch(fetchCompanies(text));
  };

  return (
    <Container className='pt-4 pb-4'>
      <Row>
        <Col>
          <Container className='pb-4 align-items-center'>
            <Row className="align-items-center">
              <Col sm='3' md='3' lg='3'>
                <h1 style={{ marginBottom: 0 }}>Compañías</h1>
              </Col>
              <Col sm='7' md='7' lg='7'>
                <SearchBar onSearch={handleSearch} parameterName={"nombre"} />
              </Col>
              {hasManageCompaniesAccess
                && (
                  <Col sm='2' md='2' lg='2'>
                    <Button variant='primary' style={{ marginTop: 0, padding: '0.8rem 1rem', marginLeft: '10px' }} onClick={handleNewLocationOpen}>Nueva compañía</Button>
                  </Col>
                )}
            </Row>
          </Container>
          {loading ? (
            <p>Cargando...</p>
          ) : error ? (
            <p>Error: {error}</p>
          ) : companies.length === 0 ? (
            <p>No se encontraron compañías.</p>
          ) : (
            <ListGroup>
              {companies.map(company => (
                <ListGroup.Item key={company.uuid} className="d-flex justify-content-between align-items-center mb-3">
                  <div>
                    <Card.Text><strong>{company.name}</strong></Card.Text>
                  </div>
                  <div className="d-flex">
                    {hasManageCompaniesAccess
                      && (
                        <ButtonGroup>
                          <Button variant="danger" style={{ backgroundColor: '#1A3B85', borderColor: '#1A3B85' }} size="lg" onClick={() => handleEditOpen(company.uuid)}><PencilSquare /></Button>
                          <Button variant="danger" size="lg" onClick={() => handleDeleteOpen(company.uuid)}><Trash /></Button>
                        </ButtonGroup>
                      )}
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}

          {/*POPUPS*/}
          <NewCompanyModal showNewCompanyModal={showNewCompanyModal} handleClose={handleNewLocationClose} />
          <EditCompanyModal showEditModal={showEditModal} handleClose={handleEditClose} company={company} />
          <DeleteCompanyModal showDeleteModal={showDeleteModal} handleClose={handleDeleteClose} company={company} />
        </Col>
      </Row>
    </Container >
  )
}

export default Companies