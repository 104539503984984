import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Home from './pages/Home'; // Asegúrate de crear este componente
import Layout from './pages/Layout'; // Asume que tienes un Layout que incluye navbar y sidebar
import Profile from './pages/Profile/Profile';
import Users from './pages/Users/Users';
import Locations from './pages/Locations/Locations';
import Companies from './pages/Companies/Companies';
import Establishments from './pages/Establishments/Establishments';
import Products from './pages/Products/Products';
import Orders from './pages/Orders/Orders';
import Certifications from './pages/Certifications/Certifications';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='profiles' element={<Profile />} />
          <Route path='users' element={<Users />} />
          <Route path='locations' element={<Locations />} />
          <Route path='companies' element={<Companies />} />
          <Route path='establishments' element={<Establishments />} />
          <Route path='products' element={<Products />} />
          <Route path='orders' element={<Orders />} />
          <Route path='certifications' element={<Certifications />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
