import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { clearProduct, editCloseMessage, editProduct, selectErrorMessage, selectSuccessMessage } from '../../redux/reducers/productsReducer';

const EditProductModal = ({ showEditModal, handleClose, product }) => {
    // Llamadas a API y Redux
    const dispatch = useDispatch();

    useEffect(() => {
        if (product) {
            setFormData({
                name: product.name,
                code: product.code,
                description: product.description,
                price: product.price,
                photos: product.photos
            });
            setInitialFormData({
                name: product.name,
                code: product.code,
                description: product.description,
                price: product.price,
                photos: product.photos
            });
        }
    }, [product]);

    //Mensaje de exito o error
    const successMessage = useSelector(selectSuccessMessage);
    const errorMessage = useSelector(selectErrorMessage);

    //Formulario
    const [formData, setFormData] = useState({
        name: '',
        code: '',
        description: '',
        price: '',
        photos: []
    });

    const handleFormChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    //Cambios de formulario
    const [initialFormData, setInitialFormData] = useState();
    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        if (initialFormData && formData) {
            const hasChanges = JSON.stringify(initialFormData) !== JSON.stringify(formData);
            setIsChanged(hasChanges);
        }
    }, [formData, initialFormData]);

    //Fotos
    const [uploadError, setUploadError] = useState('');
    const handlePhotoUpload = (e) => {
      const maxSize = 20 * 1024 * 1024; // 20 MB en bytes
        const files = Array.from(e.target.files);

        setUploadError(''); // Reset error message

        files.forEach(file => {
            if (file.size > maxSize) {
                setUploadError(`El archivo ${file.name} excede el límite de tamaño de 64KB.`);
                return;
            }

            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result;
                setFormData(prevState => ({
                    ...prevState,
                    photos: [...prevState.photos, base64String]
                }));
            };
            reader.readAsDataURL(file);
        });
    };

    const handleDeletePhoto = (index) => {
        setFormData(prevState => ({
            ...prevState,
            photos: prevState.photos.filter((_, i) => i !== index)
        }));
    };


    const handleCloseMessage = () => {
        setUploadError('')
        dispatch(clearProduct());
        handleClose();
        dispatch(editCloseMessage());
    }

    const handleSubmit = () => {
        dispatch(editProduct(product.uuid, formData));
        dispatch(clearProduct());
    };


    return (
        <div>
            <Modal show={showEditModal} onHide={handleClose} size='xl'>
                <Modal show={!!successMessage || !!errorMessage} onHide={handleCloseMessage}>
                    <Modal.Header closeButton>
                        <Modal.Title>{errorMessage ? 'Error en la creación' : 'Creación exitosa'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {successMessage || errorMessage}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseMessage}>Cerrar</Button>
                    </Modal.Footer>
                </Modal>
                <Modal.Header closeButton>
                    <Modal.Title>Editar producto</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ marginLeft: '8px' }}>
                    <form style={{ display: 'contents' }}>
                        {product && <div>
                            <Row className='pb-3'>
                                <Col sm='6' md='6' lg='6'>
                                    <Form.Control
                                        name='name'
                                        type="text"
                                        placeholder="Nombre..."
                                        value={formData.name}
                                        onChange={handleFormChange}
                                        style={{ marginTop: 0, height: '100%' }}
                                    />
                                </Col>
                                <Col sm='6' md='6' lg='6'>
                                    <Form.Control
                                        name='code'
                                        type="text"
                                        placeholder="Código..."
                                        value={formData.code}
                                        onChange={handleFormChange}
                                        style={{ marginTop: 0, height: '100%' }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Control
                                        name='description'
                                        type="text"
                                        placeholder="Descripción..."
                                        value={formData.description}
                                        onChange={handleFormChange}
                                        style={{ marginTop: 0, height: '100%' }}
                                    />
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Subir fotos</Form.Label>
                                        <Form.Control
                                            name="photos"
                                            type="file"
                                            accept="image/*"
                                            onChange={handlePhotoUpload}
                                            multiple
                                        />
                                    </Form.Group>
                                    {uploadError && <p style={{ color: 'red' }}>{uploadError}</p>}
                                </Col>
                            </Row>
                            <h5 className="pt-4 pb-2">Fotos</h5>
                            <Row>
                                {formData.photos && (formData.photos.map((photoData, index) => {
                                    const photoUrl = photoData.blob_base64 ? `data:image/jpeg;base64,${photoData.blob_base64}` : photoData;
                                    return (
                                        <Col key={index} sm="4" className="mb-3">
                                            <Card style={{ width: '350px', height: '250px', position: 'relative' }}>
                                                <Card.Img
                                                    variant="top"
                                                    src={photoUrl}
                                                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                                />
                                                <Button
                                                    variant="danger"
                                                    onClick={() => handleDeletePhoto(index)}
                                                    style={{ position: 'absolute', top: '5px', right: '5px', padding: '0.2rem 0.5rem', fontSize: '0.8rem' }}
                                                >
                                                    X
                                                </Button>
                                            </Card>
                                        </Col>
                                    );
                                }))}
                            </Row>
                        </div>
                        }
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    {!isChanged && (
                        <p className="form-text text-muted">
                            Debe realizar cambios para guardar
                        </p>
                    )}
                    <Button variant="primary" style={{ marginTop: '0px' }} onClick={handleSubmit} disabled={!isChanged}>Guardar cambios</Button>
                </Modal.Footer>
            </Modal >
        </div>
    )
}

export default EditProductModal