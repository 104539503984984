export const getOrdersStatuses = async (token) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/order_statuses`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        }
        );

        if (!response.ok) {
            throw new Error(`Failed operation: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const createOrderStatus = async (token, newOrderStatus) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/order_statuses`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(newOrderStatus)
        }
        );

        if (!response.ok) {
            throw new Error(`Failed operation: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const getOrderStatusById = async (token, idOrderStatus) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/order_statuses/${idOrderStatus}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        }
        );

        if (!response.ok) {
            throw new Error(`Failed operation: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const deleteOrderStatusById = async (token, idOrderStatus) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/order_statuses/${idOrderStatus}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        }
        );

        if (!response.ok) {
            throw new Error(`Failed operation: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const editOrderStatusById = async (token, idOrderStatus, editedOrderStatus) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/order_statuses/${idOrderStatus}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(editedOrderStatus)
        }
        );

        if (!response.ok) {
            throw new Error(`Failed operation: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};