import React, { useEffect, useState } from 'react'
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { ArrowDownShort, Search } from 'react-bootstrap-icons';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrderStatuses, selectOrderStatuses, selectOrderStatusesError, selectOrderStatusesLoading } from '../redux/reducers/orderStatusesReducer';
import { selectEstablishments } from '../redux/reducers/establishmentsReducer'; // Importar el selector de establecimientos

const OrdersFilters = ({ onSearch, parameters, setParameters }) => {
    const dispatch = useDispatch();

    // Obtener los establecimientos desde el store de Redux
    const establishments = useSelector(selectEstablishments);

    const handleSearchChange = (event) => {
        setParameters({ ...parameters, search_parameter: event.target.value });
    };

    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => setShowModal(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        const certificationParameters = {
            uuid_status: parameters.uuid_status,
            uuid_establishment: parameters.uuid_establishment, // Añadir el establecimiento seleccionado
            start_date: parameters.start_date ? formatDate(new Date(new Date(parameters.start_date))) : '',
            end_date: parameters.end_date ? formatDate(new Date(new Date(parameters.end_date))) : '',
            search_parameter: parameters.search_parameter
        };
        setShowModal(false);
        onSearch(certificationParameters);
    };

    //FECHAS
    function range(start, end, step = 1) {
        const result = [];
        for (let i = start; i < end; i += step) {
            result.push(i);
        }
        return result;
    }
    const years = range(2020, new Date().getFullYear() + 1, 1);
    const months = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
    ];

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const adjustDate = (date) => {
        const adjustedDate = new Date(date);
        adjustedDate.setDate(adjustedDate.getDate() + 1);
        return adjustedDate;
    };

    //Estados de órden
    const loadingOrderStatuses = useSelector(selectOrderStatusesLoading);
    const orderStatuses = useSelector(selectOrderStatuses);
    const errorOrderStatuses = useSelector(selectOrderStatusesError);
    useEffect(() => {
        dispatch(fetchOrderStatuses());
    }, [dispatch]);

    //Acciones
    const handleClearFilters = () => {
        setParameters({
            start_date: '',
            end_date: '',
            uuid_status: '',
            search_parameter: '',
            uuid_establishment: '' // Limpiar el establecimiento seleccionado
        });
        onSearch(parameters);
        console.log(parameters);
    };

    return (
        <div>
            <form onSubmit={handleSubmit} style={{ display: 'contents' }}>
                <Row className="align-items-center justify-content-center">
                    <Col sm='9' md='9' lg='9'>
                        <InputGroup>
                            <Form.Control
                                type="text"
                                placeholder={`Buscar por producto o cliente...`}
                                value={parameters.search_parameter}
                                onChange={handleSearchChange}
                                style={{ marginTop: 0 }}
                                aria-describedby="search-button"
                            />
                            <Button type="submit" variant='outline-dark' id="search-button">
                                <Search />
                            </Button>
                        </InputGroup>
                    </Col>
                    <Col sm='3' md='3' lg='3'>
                        <Button style={{ marginTop: 0, padding: '0.8rem 1rem', marginLeft: '10px' }} onClick={() => setShowModal(true)}>Filtros  <ArrowDownShort /></Button>
                    </Col>
                </Row>
            </form>
            <Modal show={showModal} onHide={handleCloseModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Seleccione filtros</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form style={{ display: 'contents' }}>
                        <Row>
                            <Col sm='4' md='4' lg='4'>
                                <Form.Label style={{ fontWeight: 'bold', width: "100%" }} className='pr-1'>Fecha desde</Form.Label>
                                <DatePicker
                                    required
                                    isClearable={true}
                                    selectsStart
                                    startDate={parameters.start_date ? new Date(parameters.start_date) : null}
                                    endDate={parameters.end_date ? new Date(parameters.end_date) : null}
                                    dateFormat="yyyy/MM/dd"
                                    placeholderText="Fecha desde"
                                    className="form-control"
                                    renderCustomHeader={({
                                        date,
                                        changeYear,
                                        changeMonth,
                                    }) => (
                                        <div
                                            style={{
                                                margin: 10,
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <select
                                                value={date ? date.getFullYear() : ''}
                                                onChange={({ target: { value } }) => date && changeYear(value)}
                                            >
                                                {years.map((option) => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>

                                            <select
                                                value={date ? months[date.getMonth()] : ''}
                                                onChange={({ target: { value } }) => date && changeMonth(months.indexOf(value))}
                                            >
                                                {months.map((option) => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    )}
                                    selected={parameters.start_date ? new Date(parameters.start_date) : null}
                                    onChange={(date) => {
                                        if (date) {
                                            setParameters({ ...parameters, start_date: formatDate(adjustDate(new Date(date))) });
                                        } else {
                                            setParameters({ ...parameters, start_date: null });
                                        }
                                    }}
                                />
                            </Col>
                            <Col sm='4' md='4' lg='4'>
                                <Form.Label style={{ fontWeight: 'bold', width: "100%" }}>Fecha hasta</Form.Label>
                                <DatePicker
                                    required
                                    isClearable={true}
                                    selectsEnd
                                    startDate={parameters.start_date ? new Date(parameters.start_date) : null}
                                    endDate={parameters.end_date ? new Date(parameters.end_date) : null}
                                    dateFormat="yyyy/MM/dd"
                                    placeholderText="Fecha hasta"
                                    className="form-control"
                                    renderCustomHeader={({
                                        date,
                                        changeYear,
                                        changeMonth,
                                    }) => (
                                        <div
                                            style={{
                                                margin: 10,
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <select
                                                value={date ? date.getFullYear() : ''}
                                                onChange={({ target: { value } }) => changeYear(value)}
                                            >
                                                {years.map((option) => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>

                                            <select
                                                value={date ? months[date.getMonth()] : ''}
                                                onChange={({ target: { value } }) =>
                                                    changeMonth(months.indexOf(value))
                                                }
                                            >
                                                {months.map((option) => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    )}
                                    selected={parameters.end_date ? new Date(parameters.end_date) : null}
                                    onChange={(date) => {
                                        if (date) {
                                            setParameters({ ...parameters, end_date: formatDate(adjustDate(new Date(date))) });
                                        } else {
                                            setParameters({ ...parameters, end_date: null });
                                        }
                                    }}
                                />
                            </Col>
                            <Col sm='4' md='4' lg='4'>
                                <h5>Estado de la órden</h5>
                                {loadingOrderStatuses ? (
                                    <p>Cargando estados...</p>
                                ) : errorOrderStatuses ? (
                                    <p>Error: {errorOrderStatuses}</p>
                                ) : orderStatuses.length === 0 ? (
                                    <p>No se encontraron estados.</p>
                                ) : (
                                    <Form.Select
                                        aria-label="Seleccione estado deseado"
                                        className='form-control'
                                        value={parameters.uuid_status || ""}  // Añadir esta línea
                                        onChange={(event) => {
                                            const selectedUuid = event.target.value;
                                            setParameters(prevParameters => ({
                                                ...prevParameters,
                                                uuid_status: selectedUuid
                                            }));
                                        }}
                                    >
                                        <option value="">Seleccione estado deseado</option>
                                        {orderStatuses.map(orderStatus => (
                                            <option key={orderStatus.uuid} value={orderStatus.uuid}>{orderStatus.name}</option>
                                        ))}
                                    </Form.Select>
                                )}
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col sm='12' md='12' lg='12'>
                                <h5>Establecimientos</h5>
                                {establishments.length === 0 ? (
                                    <p>No se encontraron establecimientos.</p>
                                ) : (
                                    <Form.Select
                                        aria-label="Seleccione establecimiento"
                                        className='form-control'
                                        value={parameters.uuid_establishment || ""} // Añadir esta línea
                                        onChange={(event) => {
                                            const selectedUuid = event.target.value;
                                            setParameters(prevParameters => ({
                                                ...prevParameters,
                                                uuid_establishment: selectedUuid
                                            }));
                                        }}
                                    >
                                        <option value="">Seleccione establecimiento</option>
                                        {establishments.map(establishment => (
                                            <option key={establishment.uuid} value={establishment.uuid}>{establishment.name}</option>
                                        ))}
                                    </Form.Select>
                                )}
                            </Col>
                        </Row>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Row style={{ width: "100%" }}>
                        <Col sm='6' md='6' lg='6'>
                            <Button onClick={handleClearFilters}>Borrar filtros</Button>
                        </Col>
                        <Col sm='6' md='6' lg='6'>
                            <Button onClick={handleSubmit}>Guardar filtros</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default OrdersFilters;
