export const login = (username, password) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        return {
          data: errorData,
          status: response.status,
        };
      }

      const data = await response.json();

      return {
        data: data,
        status: response.status,
      };
    } catch (error) {
      console.error('Login error:', error);
      return {
        data: { message: 'Conexión no disponible' },
        status: 500,
      };
    }
  };
};
