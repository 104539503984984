import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonGroup, Card, ListGroup, Form, Pagination } from 'react-bootstrap';
import { PencilSquare, PersonCircle, Trash } from 'react-bootstrap-icons';
import { fetchUserById } from '../../redux/reducers/usersReducer';
import { selectLoggedUserProfile } from '../../redux/reducers/authReducer';
import { selectEstablishments } from '../../redux/reducers/establishmentsReducer';

const EmpleadosView = ({ employees, setShowDeleteModal, setShowEditModal }) => {
  const dispatch = useDispatch();
  const profilesAuth = useSelector(selectLoggedUserProfile);
  const establishments = useSelector(selectEstablishments);

  const [selectedEstablishment, setSelectedEstablishment] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 6;

  const handleDeleteOpen = (uuiduser) => {
    dispatch(fetchUserById(uuiduser));
    setShowDeleteModal(true);
  };

  const handleEditOpen = (useruuid) => {
    dispatch(fetchUserById(useruuid));
    setShowEditModal(true);
  };

  const hasManageEmployeesAccess = profilesAuth?.some(profile =>
    profile.accesses?.some(access => access.name === 'manage_employees')
  );

  const employeesArray = Array.isArray(employees) ? employees : employees?.employees;

  // Filtrar empleados por establecimiento seleccionado
  const filteredEmployees = selectedEstablishment
    ? employeesArray.filter(employee =>
      employee.user.establishments_codes.includes(selectedEstablishment))
    : employeesArray;

  // Calcular los empleados para la página actual
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredEmployees.slice(indexOfFirstUser, indexOfLastUser);

  // Cambiar de página
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calcular el número total de páginas
  const totalPages = Math.ceil(filteredEmployees.length / usersPerPage);

  return (
    <>
      <Form.Group controlId="establishmentFilter" className="mb-3">
        <Form.Label>Seleccione establecimiento para filtrar</Form.Label>
        <Form.Control
          as="select"
          value={selectedEstablishment}
          onChange={(e) => setSelectedEstablishment(e.target.value)}
          className='w-50'
        >
          <option value="">Sin filtro</option>
          {establishments.map(establishment => (
            <option key={establishment.uuid} value={establishment.code}>
              {establishment.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      <ListGroup>
        {currentUsers.map(employee => (
          <ListGroup.Item key={employee.uuid} className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex align-items-center" style={{ width: '100%' }}>
              <PersonCircle size={35} className="me-3" />
              <div style={{ width: '35%' }}>
                <Card.Text className="mb-2"><strong>{employee.first_name} {employee.last_name}</strong></Card.Text>
                <Card.Text className="mb-2">Posición: {employee.position}</Card.Text>
              </div>
              <div className="ms-5" style={{ width: '65%' }}>
                <Card.Text className="mb-2"><strong>Establecimientos asociados</strong></Card.Text>
                <Card.Text className="mb-2">{employee.user.establishments_codes.join(', ')}</Card.Text>
              </div>
            </div>
            <div className="d-flex">
              {hasManageEmployeesAccess
                && (
                  <ButtonGroup>
                    <Button variant="danger" style={{ backgroundColor: '#1A3B85', borderColor: '#1A3B85' }} size="lg" onClick={() => handleEditOpen(employee.uuid)}><PencilSquare /></Button>
                    <Button variant="danger" size="lg" onClick={() => handleDeleteOpen(employee.uuid)}><Trash /></Button>
                  </ButtonGroup>
                )}
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>

      {/* Componente de Paginación centrado */}
      <div className="d-flex justify-content-center mt-4">
        <Pagination>
          <Pagination.Prev 
            onClick={() => paginate(currentPage > 1 ? currentPage - 1 : 1)}
            disabled={currentPage === 1}
          >
            Anterior
          </Pagination.Prev>
          <Pagination.Item active>{`Página ${currentPage} de ${totalPages}`}</Pagination.Item>
          <Pagination.Next 
            onClick={() => paginate(currentPage < totalPages ? currentPage + 1 : totalPages)}
            disabled={currentPage === totalPages}
          >
            Siguiente
          </Pagination.Next>
        </Pagination>
      </div>
    </>
  );
}

export default EmpleadosView;
