// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './reducers/authReducer';
import profileReducer from './reducers/profileReducer';
import modulesReducer from './reducers/modulesReducer';
import accessesReducer from './reducers/accessesReducer';
import usersReducer from './reducers/usersReducer';
import locationsReducer from './reducers/locationsReducer';
import companiesReducer from './reducers/companiesReducer';
import establishmentsReducer from './reducers/establishmentsReducer';
import productsReducer from './reducers/productsReducer';
import ordersReducer from './reducers/ordersReducer';
import orderStatusesReducer from './reducers/orderStatusesReducer';

const store = configureStore({
  reducer: {
    auth: authReducer,
    profile: profileReducer,
    modules: modulesReducer,
    accesses: accessesReducer,
    users: usersReducer,
    locations: locationsReducer,
    companies: companiesReducer,
    establishments: establishmentsReducer,
    products: productsReducer,
    orders: ordersReducer,
    orderStatuses: orderStatusesReducer
  },
});

export default store;
