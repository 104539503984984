import { createSlice } from '@reduxjs/toolkit';
import { createOrderStatus, deleteOrderStatusById, getOrderStatusById, getOrdersStatuses } from '../actions/orderStatusesThunks';
import { withAuthentication } from '../middlewares';

const initialState = {
    data: [],
    orderStatus: null,
    loading: false,
    error: null,
    successMessage: '',
    errorMessage: '',
};

const orderStatusesSlice = createSlice({
    name: 'orderStatuses',
    initialState,
    reducers: {
        editCloseMessage(state) {
            state.successMessage = '';
            state.errorMessage = '';
        },
        //Traer todos los ordenes
        fetchOrderStatusesStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchOrderStatusesSuccess(state, action) {
            state.loading = false;
            state.data = action.payload;
        },
        fetchOrderStatusesFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        //Crear estado de orden
        createNewOrderStatusStart(state) {
            state.loading = true;
            state.error = null;
        },
        createNewOrderStatusSuccess(state, action) {
            state.loading = false;
            state.orderStatus = action.payload;
            state.successMessage = 'Estado de órden creada exitosamente';
            state.errorMessage = '';
        },
        createNewOrderStatusFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.errorMessage = 'Error al crear estado de órden';
            state.successMessage = '';
        },
        //Borrar una estado de órden
        deleteOrderStatusStart(state) {
            state.loading = true;
            state.error = null;
        },
        deleteOrderStatusSuccess(state, action) {
            state.loading = false;
            state.error = null;
            //Borrar estado de órden del estado
            const orderstatusuuid = action.payload;
            const deletedOrderStatusIndex = state.data.findIndex(orderStatus => orderStatus.uuid === orderstatusuuid);

            if (deletedOrderStatusIndex !== -1) {
                state.data.splice(deletedOrderStatusIndex, 1);
                if (state.orderStatus && state.orderStatus.uuid === orderstatusuuid) {
                    state.orderStatus = null;
                }
            }
        },
        deleteOrderStatusFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        //Traer una estado de órden para edición/detalle
        fetchOrderStatusestart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchOrderStatusesuccess(state, action) {
            state.loading = false;
            state.orderStatus = action.payload;
        },
        fetchOrderStatusFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        clearOrderStatus(state) {
            state.orderStatus = null;
        },
        //Editar una estado de órden
        editOrderStatusStart(state) {
            state.loading = true;
            state.error = null;
        },
        editOrderStatusSuccess(state, action) {
            state.loading = false;
            state.orderStatus = action.payload;
            state.successMessage = 'Operación exitosa';
            state.errorMessage = '';
        },
        editOrderStatusFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.errorMessage = 'Error en la operación';
            state.successMessage = '';
        },
    },
});

export const fetchOrderStatuses = withAuthentication((authToken) => async (dispatch, getState) => {
    dispatch(fetchOrderStatusesStart());
    try {
        const orders = await getOrdersStatuses(authToken);
        dispatch(fetchOrderStatusesSuccess(orders));
    } catch (error) {
        dispatch(fetchOrderStatusesFailure(error.message));
    }
});

export const createNewOrderStatus = withAuthentication((authToken, newOrder) => async (dispatch, getState) => {
    dispatch(createNewOrderStatusStart());
    try {
        const order = await createOrderStatus(authToken, newOrder);
        dispatch(createNewOrderStatusSuccess(order));
    } catch (error) {
        dispatch(createNewOrderStatusFailure(error.message));
    }
});

export const fetchOrderStatusById = withAuthentication((authToken, orderstatusuuid) => async (dispatch, getState) => {
    dispatch(fetchOrderStatusestart());
    try {
        const order = await getOrderStatusById(authToken, orderstatusuuid);
        dispatch(fetchOrderStatusesuccess(order));
    } catch (error) {
        dispatch(fetchOrderStatusFailure(error.message));
    }
});

export const deleteOrderStatus = withAuthentication((authToken, orderstatusuuid) => async (dispatch, getState) => {
    dispatch(deleteOrderStatusStart());
    try {
        await deleteOrderStatusById(authToken, orderstatusuuid)
        dispatch(deleteOrderStatusSuccess(orderstatusuuid));
    } catch (error) {
        dispatch(deleteOrderStatusFailure(error.message));
    }
});

// export const editOrderStatus = withAuthentication((authToken, orderstatusuuid, orderName) => async (dispatch, getState) => {
//     dispatch(editOrderStatusStart());
//     try {
//         const order = await editOrderStatusById(authToken, orderstatusuuid, orderName)
//         dispatch(editOrderStatusSuccess(order));
//     } catch (error) {
//         dispatch(editOrderStatusFailure(error.message));
//     }
// });

export const {
    editCloseMessage,
    fetchOrderStatusesStart,
    fetchOrderStatusesSuccess,
    fetchOrderStatusesFailure,
    createNewOrderStatusStart,
    createNewOrderStatusSuccess,
    createNewOrderStatusFailure,
    fetchOrderStatusestart,
    fetchOrderStatusesuccess,
    fetchOrderStatusFailure,
    deleteOrderStatusStart,
    deleteOrderStatusSuccess,
    deleteOrderStatusFailure,
    editOrderStatusStart,
    editOrderStatusSuccess,
    editOrderStatusFailure,
    clearOrderStatus
} = orderStatusesSlice.actions;

export const selectOrderStatuses = (state) => state.orderStatuses.data;
export const selectOrderStatusesLoading = (state) => state.orderStatuses.loading;
export const selectOrderStatusesError = (state) => state.orderStatuses.error;
export const selectOrderStatus = (state) => state.orderStatuses.orderStatus;
export const selectSuccessMessage = (state) => state.orderStatuses.successMessage;
export const selectErrorMessage = (state) => state.orderStatuses.errorMessage;

export default orderStatusesSlice.reducer;
