import React, { useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { deleteProfile } from '../../redux/reducers/profileReducer';

const DeleteProfileModal = ({ showDeleteModal, handleClose, profile }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const handleDelete = () => {
        setLoading(true);
        dispatch(deleteProfile(profile.uuid)).finally(() => {
            setLoading(false);
            handleClose();
        });
    };

    return (
        <>
            {profile && (
                <Modal show={showDeleteModal} onHide={handleClose} size='md'>
                    <Modal.Header closeButton>
                        <Modal.Title>¿Desea borrar el perfil {profile.name}?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Se borrará el perfil con todos los accesos asociados.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleDelete} disabled={loading}>
                            {loading ? <Spinner animation="border" size="sm" /> : 'Borrar'}
                        </Button>
                        <Button variant="outline-primary" onClick={handleClose} disabled={loading}>
                            Cancelar
                        </Button>
                    </Modal.Footer>
                </Modal >
            )}
        </>
    );
}

export default DeleteProfileModal;
