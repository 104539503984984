import React, { useState } from 'react'
import { Button, Col, Form, Modal, Row, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createNewProduct, editCloseMessage, fetchProducts, selectErrorMessage, selectSuccessMessage } from '../../redux/reducers/productsReducer';

const NewProductModal = ({ showNewProductModal, handleClose }) => {
    // Llamadas a API y Redux
    const dispatch = useDispatch();

    //Mensaje de exito o error
    const successMessage = useSelector(selectSuccessMessage);
    const errorMessage = useSelector(selectErrorMessage);

    //Formulario
    const [formData, setFormData] = useState({
        name: '',
        code: '',
        description: '',
        price: '',
        establishments: [],
        photos: []
    });

    const handleFormChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    //Fotos
    const handlePhotoUpload = (e) => {
        const files = Array.from(e.target.files);

        files.forEach(file => {
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result;
                setFormData(prevState => ({
                    ...prevState,
                    photos: [...prevState.photos, base64String]
                }));
            };
            reader.readAsDataURL(file);
        });
    };

    const handleDeletePhoto = (index) => {
        setFormData(prevState => ({
            ...prevState,
            photos: prevState.photos.filter((_, i) => i !== index)
        }));
    };

    //Acciones
    const handleCloseMessage = () => {
        dispatch(editCloseMessage());
        if (successMessage) {
            handleClose();
            setFormData({
                name: '',
                code: '',
                description: '',
                price: 0.0,
                establishments: [],
                photos: []
            });
        }
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(createNewProduct(formData));
        dispatch(fetchProducts(''))
    };


    return (
        <div>
            <Modal show={showNewProductModal} onHide={handleClose} size='xl'>
                <Modal show={!!successMessage || !!errorMessage} onHide={handleCloseMessage}>
                    <Modal.Header closeButton>
                        <Modal.Title>{errorMessage ? 'Error en la creación' : 'Creación exitosa'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {successMessage || errorMessage}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseMessage}>Cerrar</Button>
                    </Modal.Footer>
                </Modal>
                <Modal.Header closeButton>
                    <Modal.Title>Nuevo producto</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ marginLeft: '8px' }}>
                    <form style={{ display: 'contents' }} onSubmit={handleSubmit}>
                        <div>
                            <Row className='pb-3'>
                                <Col sm='6' md='6' lg='6'>
                                    <Form.Control
                                        name='name'
                                        type="text"
                                        placeholder="Nombre..."
                                        value={formData.name}
                                        onChange={handleFormChange}
                                        style={{ marginTop: 0, height: '100%' }}
                                        required
                                    />
                                </Col>
                                <Col sm='6' md='6' lg='6'>
                                    <Form.Control
                                        name='code'
                                        type="text"
                                        placeholder="Código..."
                                        value={formData.code}
                                        onChange={handleFormChange}
                                        style={{ marginTop: 0, height: '100%' }}
                                        required
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Control
                                        name='description'
                                        type="text"
                                        placeholder="Descripción..."
                                        value={formData.description}
                                        onChange={handleFormChange}
                                        style={{ marginTop: 0, height: '100%' }}
                                    />
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Subir fotos</Form.Label>
                                        <Form.Control
                                            name="photos"
                                            type="file"
                                            accept="image/*"
                                            onChange={handlePhotoUpload}
                                            multiple
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <h5 className="pt-4 pb-2">Fotos</h5>
                            <Row>
                                {formData.photos.map((photoUrl, index) => (
                                    <Col key={index} sm="4" className="mb-3">
                                        <Card style={{ width: '350px', height: '250px', position: 'relative' }}>
                                            <Card.Img
                                                variant="top"
                                                src={photoUrl}
                                                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                            />
                                            <Button
                                                variant="danger"
                                                onClick={() => handleDeletePhoto(index)}
                                                style={{ position: 'absolute', top: '5px', right: '5px', padding: '0.2rem 0.5rem', fontSize: '0.8rem' }}
                                            >
                                                X
                                            </Button>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                        <Modal.Footer>
                            <Button variant="primary" type='submit'>Crear producto</Button>
                        </Modal.Footer>
                    </form>
                </Modal.Body>
            </Modal >
        </div>
    )
}

export default NewProductModal