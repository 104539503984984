import React, { useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createNewCompany, editCloseMessage, fetchCompanies, selectErrorMessage, selectSuccessMessage } from '../../redux/reducers/companiesReducer';

const NewCompanyModal = ({ showNewCompanyModal, handleClose }) => {
    // Llamadas a API y Redux
    const dispatch = useDispatch();

    //Mensaje de exito o error
    const successMessage = useSelector(selectSuccessMessage);
    const errorMessage = useSelector(selectErrorMessage);

    //Nombre y descripcion
    const [name, setName] = useState('');

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    //Acciones
    const handleCloseMessage = () => {
        handleClose();
        setName('')
        dispatch(editCloseMessage());
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(createNewCompany(name));
        dispatch(fetchCompanies(''))
    };


    return (
        <div>
            <Modal show={showNewCompanyModal} onHide={handleClose} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva compañía</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ marginLeft: '8px' }}>
                    <form style={{ display: 'contents' }} onSubmit={handleSubmit}>
                        <div>
                            <Row className='pb-2'>
                                <Col sm='12' md='12' lg='12'>
                                    <Form.Control
                                        type="text"
                                        placeholder="Nombre de la compañía..."
                                        value={name}
                                        onChange={handleNameChange}
                                        style={{ marginTop: 0, height: '100%' }}
                                        required
                                    />
                                </Col>
                            </Row>
                        </div>
                        <Modal.Footer>
                            <Button variant="primary" type='submit'>Crear compañía</Button>
                        </Modal.Footer>
                    </form>
                </Modal.Body>
                <Modal show={!!successMessage || !!errorMessage} onHide={handleCloseMessage}>
                    <Modal.Header closeButton>
                        <Modal.Title>{errorMessage ? 'Error en la creación' : 'Creación exitosa'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {successMessage || errorMessage}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseMessage}>Cerrar</Button>
                    </Modal.Footer>
                </Modal>
            </Modal >
        </div>
    )
}

export default NewCompanyModal