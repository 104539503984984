import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, ListGroup, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLocations, selectLocations, selectLocationsError, selectLocationsLoading } from '../../redux/reducers/locationsReducer';
import { createNewEstablishment, editCloseMessage, fetchEstablishments, selectErrorMessage, selectSuccessMessage } from '../../redux/reducers/establishmentsReducer';
import { fetchProducts, selectProducts, selectProductsError, selectProductsLoading } from '../../redux/reducers/productsReducer';
import { fetchCompanies, selectCompanies, selectCompaniesError, selectCompaniesLoading } from '../../redux/reducers/companiesReducer';

const NewEstablishmentModal = ({ showNewEstablishmentModal, handleClose }) => {
  const dispatch = useDispatch();

  const successMessage = useSelector(selectSuccessMessage);
  const errorMessage = useSelector(selectErrorMessage);

  // Productos
  const loadingProducts = useSelector(selectProductsLoading);
  const products = useSelector(selectProducts);
  const errorProducts = useSelector(selectProductsError);
  useEffect(() => {
    dispatch(fetchProducts(''));
  }, [dispatch]);

  // Locations
  const loadingLocations = useSelector(selectLocationsLoading);
  const locations = useSelector(selectLocations);
  const errorLocations = useSelector(selectLocationsError);
  useEffect(() => {
    dispatch(fetchLocations(''));
  }, [dispatch]);

  // Companies
  const loadingCompanies = useSelector(selectCompaniesLoading);
  const companies = useSelector(selectCompanies);
  const errorCompanies = useSelector(selectCompaniesError);
  useEffect(() => {
    dispatch(fetchCompanies(''));
  }, [dispatch]);

  const handleSelectLocation = (event) => {
    const selectedLocationUuid = event.target.value;
    setFormData(prevFormData => ({
      ...prevFormData,
      uuid_location: selectedLocationUuid
    }));
  };

  // Formulario
  const [formData, setFormData] = useState({
    name: '',
    code: '',
    offers_delivery: false,
    offers_dining: false,
    uuid_location: '',
    products: [],
    companies: []
  });

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Productos
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [availableProducts, setAvailableProducts] = useState([]);
  useEffect(() => {
    setAvailableProducts(products);
  }, [products]);

  const handleSelectProduct = (event) => {
    const selectedProductId = event.target.value;
    const selectedProduct = availableProducts.find(product => product.uuid === selectedProductId);

    setSelectedProducts([...selectedProducts, selectedProduct]);
    setFormData({
      ...formData,
      products: [...formData.products, selectedProductId]
    })

    setAvailableProducts(availableProducts.filter(product => product.uuid !== selectedProductId));
  };

  const handleRemoveProduct = (productToRemove) => {
    setAvailableProducts([...availableProducts, productToRemove]);

    setSelectedProducts(selectedProducts.filter(product => product.uuid !== productToRemove.uuid));
    setFormData({
      ...formData,
      products: formData.products.filter(productId => productId !== productToRemove.uuid)
    });
  };

  // Companies
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [availableCompanies, setAvailableCompanies] = useState([]);
  useEffect(() => {
    setAvailableCompanies(companies);
  }, [companies]);

  const handleSelectCompany = (event) => {
    const selectedCompanyId = event.target.value;
    const selectedCompany = availableCompanies.find(company => company.uuid === selectedCompanyId);

    setSelectedCompanies([...selectedCompanies, selectedCompany]);
    setFormData({
      ...formData,
      companies: [...formData.companies, selectedCompanyId]
    });

    setAvailableCompanies(availableCompanies.filter(company => company.uuid !== selectedCompanyId));
  };

  const handleRemoveCompany = (companyToRemove) => {
    setAvailableCompanies([...availableCompanies, companyToRemove]);

    setSelectedCompanies(selectedCompanies.filter(company => company.uuid !== companyToRemove.uuid));
    setFormData({
      ...formData,
      companies: formData.companies.filter(companyId => companyId !== companyToRemove.uuid)
    });
  };

  const handleCloseMessage = () => {
    dispatch(editCloseMessage());
    if (successMessage) {
      setFormData({
        name: '',
        code: '',
        offers_delivery: false,
        offers_dining: false,
        products: [],
        companies: [],
        uuid_location: ''
      });
      setSelectedProducts([]); // Limpia el arreglo de productos seleccionados
      setAvailableProducts(products); // Reinicia los productos disponibles
      setSelectedCompanies([]); // Limpia el arreglo de compañías seleccionadas
      setAvailableCompanies(companies); // Reinicia las compañías disponibles
      handleClose();
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(createNewEstablishment(formData));
    dispatch(fetchEstablishments(''));
  };

  return (
    <div>
      <Modal show={showNewEstablishmentModal} onHide={handleClose} size='xl'>
        <Modal show={!!successMessage || !!errorMessage} onHide={handleCloseMessage}>
          <Modal.Header closeButton>
            <Modal.Title>{errorMessage ? 'Error en la creación' : 'Creación exitosa'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {successMessage || errorMessage}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseMessage}>Cerrar</Button>
          </Modal.Footer>
        </Modal>
        <Modal.Header closeButton>
          <Modal.Title>Nuevo establecimiento</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ marginLeft: '8px' }}>
          <form style={{ display: 'contents' }} onSubmit={handleSubmit}>
            <div>
              <Row className='pb-4'>
                <Col sm='6' md='6' lg='6'>
                  <Form.Control
                    name='name'
                    type="text"
                    placeholder="Nombre..."
                    value={formData.name}
                    onChange={handleFormChange}
                    style={{ marginTop: 0, height: '100%' }}
                    required
                  />
                </Col>
                <Col sm='6' md='6' lg='6'>
                  <Form.Control
                    name='code'
                    type="text"
                    placeholder="Código..."
                    value={formData.code}
                    onChange={handleFormChange}
                    style={{ marginTop: 0, height: '100%' }}
                    required
                  />
                </Col>
              </Row>
              <Row className='pb-2'>
                {loadingLocations ? (
                  <p>Cargando ubicaciones...</p>
                ) : errorLocations ? (
                  <p>Error: {errorLocations}</p>
                ) : locations.length === 0 ? (
                  <p>No se encontraron ubicaciones</p>
                ) : (
                  <Col m='5' md='5' lg='5'>
                    <Form.Select aria-label="Selecciona una ubicación"
                      className='form-control'
                      onChange={handleSelectLocation}
                      value={formData.uuid_location}
                      required
                    >
                      <option value="">Selecciona una ubicación</option>
                      {locations.map(locations => (
                        <option key={locations.uuid} value={locations.uuid}>{locations.name}</option>
                      ))}
                    </Form.Select>
                  </Col>
                )}
                <Col m='7' md='7' lg='7'>
                  <h6>Seleccione respectivamente</h6>
                  <Row>
                    <Col m='6' md='6' lg='6'>
                      <Form.Check
                        type="checkbox"
                        label='Kiosko'
                        onChange={(e) => setFormData({ ...formData, offers_delivery: e.target.checked })}
                        checked={formData.offers_delivery}
                      />
                    </Col>
                    <Col m='6' md='6' lg='6'>
                      <Form.Check
                        type="checkbox"
                        label='Comedor'
                        onChange={(e) => setFormData({ ...formData, offers_dining: e.target.checked })}
                        checked={formData.offers_dining}
                      />
                    </Col>
                  </Row>
                </Col>
                <h5 className='pt-3 pb-2'>Productos asociados</h5>
                {loadingProducts ? (
                  <p>Cargando productos...</p>
                ) : errorProducts ? (
                  <p>Error: {errorProducts}</p>
                ) : products.length === 0 ? (
                  <p>No se encontraron productos.</p>
                ) : (
                  <Row>
                    <Col m='7' md='7' lg='7'>
                      <ListGroup>
                        {selectedProducts.length === 0 ? (
                          <p>Sin productos seleccionados aún</p>
                        ) : (selectedProducts.map(product => (
                          <ListGroup.Item key={product.uuid} className="d-flex justify-content-between align-items-center mb-3">
                            <div>
                              <Card.Text style={{ marginBottom: '0rem' }}><strong>{product.name}</strong></Card.Text>
                              <Card.Text>Código: {product.code}</Card.Text>
                            </div>
                            <div className="d-flex">
                              <Button variant="danger" size="md" onClick={() => handleRemoveProduct(product)}>x</Button>
                            </div>
                          </ListGroup.Item>
                        )))}
                      </ListGroup>
                    </Col>
                    <Col m='5' md='5' lg='5'>
                      <Form.Select aria-label="Agregar productos asociados" className='form-control' onChange={handleSelectProduct}>
                        <option value="">Agregar productos asociados</option>
                        {availableProducts.map(product => (
                          <option key={product.uuid} value={product.uuid}>{product.name}</option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                )}
                <h5 className='pt-3 pb-2'>Compañías asociadas</h5>
                {loadingCompanies ? (
                  <p>Cargando compañías...</p>
                ) : errorCompanies ? (
                  <p>Error: {errorCompanies}</p>
                ) : companies.length === 0 ? (
                  <p>No se encontraron compañías.</p>
                ) : (
                  <Row>
                    <Col m='7' md='7' lg='7'>
                      <ListGroup>
                        {selectedCompanies.length === 0 ? (
                          <p>Sin compañías seleccionadas aún</p>
                        ) : (selectedCompanies.map(company => (
                          <ListGroup.Item key={company.uuid} className="d-flex justify-content-between align-items-center mb-3">
                            <div>
                              <Card.Text style={{ marginBottom: '0rem' }}><strong>{company.name}</strong></Card.Text>
                              <Card.Text> </Card.Text>
                            </div>
                            <div className="d-flex">
                              <Button variant="danger" size="md" onClick={() => handleRemoveCompany(company)}>x</Button>
                            </div>
                          </ListGroup.Item>
                        )))}
                      </ListGroup>
                    </Col>
                    <Col m='5' md='5' lg='5'>
                      <Form.Select aria-label="Agregar compañías asociadas" className='form-control' onChange={handleSelectCompany}>
                        <option value="">Agregar compañías asociadas</option>
                        {availableCompanies.map(company => (
                          <option key={company.uuid} value={company.uuid}>{company.name}</option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                )}
              </Row>
            </div>
            <Modal.Footer>
              <Button variant="primary" type='submit'>Crear establecimiento</Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal >
    </div>
  )
}

export default NewEstablishmentModal;
