import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { deleteEstablishment } from '../../redux/reducers/establishmentsReducer';
import { Button, Modal, Spinner } from 'react-bootstrap';

const DeleteEstablishmentModal = ({ showDeleteModal, handleClose, establishment }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const handleDelete = () => {
        setLoading(true);
        dispatch(deleteEstablishment(establishment.uuid)).finally(() => {
            setLoading(false);
            handleClose();
        });
    };

    return (
        <>
            {establishment && (
                <Modal show={showDeleteModal} onHide={handleClose} size='md'>
                    <Modal.Header closeButton>
                        <Modal.Title>¿Desea borrar el establecimiento {establishment.name}?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Se borrará el establecimiento de todos los productos relacionados.</p>
                        {/* TODO: ESTABLECIMIENTOS RELACIONADOS??? */}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleDelete} disabled={loading}>
                            {loading ? <Spinner animation="border" size="sm" /> : 'Borrar'}
                        </Button>
                        <Button variant="outline-primary" onClick={handleClose} disabled={loading}>
                            Cancelar
                        </Button>
                    </Modal.Footer>
                </Modal >
            )}
        </>
    );
}

export default DeleteEstablishmentModal