import { createSlice } from '@reduxjs/toolkit';
import { createEstablishment, deleteEstablishmentById, editEstablishmentById, getEstablishmentById, getEstablishmentsForCertifications, getEstablishmentsWithSearch } from '../actions/establishmentsThunks';
import { withAuthentication } from '../middlewares';

const initialState = {
    data: [],
    establishment: null,
    loading: false,
    error: null,
    successMessage: '',
    errorMessage: '',
};

const establishmentsSlice = createSlice({
    name: 'establisments',
    initialState,
    reducers: {
        editCloseMessage(state) {
            state.successMessage = '';
            state.errorMessage = '';
        },
        //Traer todos los establecimientos
        fetchEstablishmentsStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchEstablishmentsSuccess(state, action) {
            state.loading = false;
            state.data = action.payload;
        },
        fetchEstablishmentsFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        //Crear establecimiento
        createNewEstablishmentStart(state) {
            state.loading = true;
            state.error = null;
        },
        createNewEstablishmentSuccess(state, action) {
            state.loading = false;
            state.establishment = action.payload;
            state.successMessage = 'Establecimiento creado exitosamente';
            state.errorMessage = '';
        },
        createNewEstablishmentFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.errorMessage = 'Error al crear establecimiento';
            state.successMessage = '';
        },
        //Borrar una establecimiento
        deleteEstablishmentStart(state) {
            state.loading = true;
            state.error = null;
        },
        deleteEstablishmentSuccess(state, action) {
            state.loading = false;
            state.error = null;
            //Borrar establecimiento del estado
            const establishmentuuid = action.payload;
            const deletedEstablishmentIndex = state.data.findIndex(establishment => establishment.uuid === establishmentuuid);

            if (deletedEstablishmentIndex !== -1) {
                state.data.splice(deletedEstablishmentIndex, 1);
                if (state.establishment && state.establishment.uuid === establishmentuuid) {
                    state.establishment = null;
                }
            }
        },
        deleteEstablishmentFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        //Traer una establecimiento para edición/detalle
        fetchEstablishmentStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchEstablishmentSuccess(state, action) {
            state.loading = false;
            state.establishment = action.payload;
        },
        fetchEstablishmentFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        clearEstablishment(state) {
            state.establishment = null;
        },
        //Editar una establecimiento
        editEstablishmentStart(state) {
            state.loading = true;
            state.error = null;
        },
        editEstablishmentSuccess(state, action) {
            state.loading = false;
            state.establishment = action.payload;
            state.successMessage = 'Operación exitosa';
            state.errorMessage = '';
        },
        editEstablishmentFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.errorMessage = 'Error en la operación';
            state.successMessage = '';
        },
    },
});

export const fetchEstablishments = withAuthentication((authToken, establishmentName) => async (dispatch, getState) => {
    dispatch(fetchEstablishmentsStart());
    try {
        const establishments = await getEstablishmentsWithSearch(authToken, establishmentName);
        dispatch(fetchEstablishmentsSuccess(establishments));
    } catch (error) {
        dispatch(fetchEstablishmentsFailure(error.message));
    }
});

export const fetchEstablishmentsForCertifications = withAuthentication((authToken) => async (dispatch, getState) => {
    dispatch(fetchEstablishmentsStart());
    try {
        const establishments = await getEstablishmentsForCertifications(authToken);
        dispatch(fetchEstablishmentsSuccess(establishments));
    } catch (error) {
        dispatch(fetchEstablishmentsFailure(error.message));
    }
});

export const createNewEstablishment = withAuthentication((authToken, newEstablishment) => async (dispatch, getState) => {
    dispatch(createNewEstablishmentStart());
    try {
        const establishment = await createEstablishment(authToken, newEstablishment);
        dispatch(createNewEstablishmentSuccess(establishment));
    } catch (error) {
        dispatch(createNewEstablishmentFailure(error.message));
    }
});

export const fetchEstablishmentById = withAuthentication((authToken, establishmentuuid) => async (dispatch, getState) => {
    dispatch(fetchEstablishmentStart());
    try {
        const establishment = await getEstablishmentById(authToken, establishmentuuid);
        dispatch(fetchEstablishmentSuccess(establishment));
    } catch (error) {
        dispatch(fetchEstablishmentFailure(error.message));
    }
});

export const deleteEstablishment = withAuthentication((authToken, establishmentuuid) => async (dispatch, getState) => {
    dispatch(deleteEstablishmentStart());
    try {
        await deleteEstablishmentById(authToken, establishmentuuid)
        dispatch(deleteEstablishmentSuccess(establishmentuuid));
    } catch (error) {
        dispatch(deleteEstablishmentFailure(error.message));
    }
});

export const editEstablishment = withAuthentication((authToken, establishmentuuid, editedEstablishment) => async (dispatch, getState) => {
    dispatch(editEstablishmentStart());
    try {
        const establishment = await editEstablishmentById(authToken, establishmentuuid, editedEstablishment)
        dispatch(editEstablishmentSuccess(establishment));
    } catch (error) {
        dispatch(editEstablishmentFailure(error.message));
    }
});

export const {
    editCloseMessage,
    fetchEstablishmentsStart,
    fetchEstablishmentsSuccess,
    fetchEstablishmentsFailure,
    createNewEstablishmentStart,
    createNewEstablishmentSuccess,
    createNewEstablishmentFailure,
    fetchEstablishmentStart,
    fetchEstablishmentSuccess,
    fetchEstablishmentFailure,
    deleteEstablishmentStart,
    deleteEstablishmentSuccess,
    deleteEstablishmentFailure,
    editEstablishmentStart,
    editEstablishmentSuccess,
    editEstablishmentFailure,
    clearEstablishment
} = establishmentsSlice.actions;

export const selectEstablishments = (state) => state.establishments.data;
export const selectEstablishmentsLoading = (state) => state.establishments.loading;
export const selectEstablishmentsError = (state) => state.establishments.error;
export const selectEstablishment = (state) => state.establishments.establishment;
export const selectSuccessMessage = (state) => state.establishments.successMessage;
export const selectErrorMessage = (state) => state.establishments.errorMessage;

export default establishmentsSlice.reducer;
