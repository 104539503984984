import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearOrder, fetchOrderById, fetchOrders, selectOrder, selectOrders, selectOrdersError, selectOrdersLoading, editOrderStatus } from '../../redux/reducers/ordersReducer';
import { selectLoggedUserProfile } from '../../redux/reducers/authReducer';
import { Badge, Button, Card, Col, Container, ListGroup, Row } from 'react-bootstrap';
import { ArrowClockwise, EyeFill } from 'react-bootstrap-icons';
import NewOrderModal from './NewOrderModal';
import DetailOrderModal from './DetailOrderModal';
import OrdersFilters from '../../components/OrdersFilters';
import '../styles/Orders.css';
import OrderStatusDropdown from '../../components/OrderStatusDropdown';
import { fetchOrderStatuses, selectOrderStatuses, selectOrderStatusesError, selectOrderStatusesLoading } from '../../redux/reducers/orderStatusesReducer';

const Orders = () => {
  const dispatch = useDispatch();
  const loading = useSelector(selectOrdersLoading);
  const ordersData = useSelector(selectOrders) || {}; // Asegurarme de que ordersData esté definido
  const order = useSelector(selectOrder);
  const error = useSelector(selectOrdersError);
  const profiles = useSelector(selectLoggedUserProfile);
  const [parameters, setParameters] = useState({
    start_date: '',
    end_date: '',
    uuid_status: '',
    search_parameter: '',
    page: 1, // Página actual
    per_page: 6 // Elementos por página
  });

  useEffect(() => {
    dispatch(fetchOrders(parameters));
  }, [dispatch, parameters.page]);

  const hasManageOrdersAccess = profiles?.some(profile =>
    profile.accesses?.some(access => access.name === 'manage_orders')
  );

  // Order statuses
  const loadingOrderStatuses = useSelector(selectOrderStatusesLoading);
  const orderStatuses = useSelector(selectOrderStatuses);
  const errorOrderStatuses = useSelector(selectOrderStatusesError);

  useEffect(() => {
    dispatch(fetchOrderStatuses());
  }, [dispatch]);

  // Popups
  const [showDetailModal, setshowDetailModal] = useState(false);
  const [showNewOrderModal, setShowNewOrderModal] = useState(false);

  const handleDetailOpen = (orderuuid) => {
    dispatch(fetchOrderById(orderuuid));
    setshowDetailModal(true);
  };

  const handleDetailClose = () => {
    dispatch(clearOrder());
    setshowDetailModal(false);
  };

  const handleChangeStatus = (orderuuid, newStatus) => {
    dispatch(editOrderStatus(orderuuid, newStatus));
  };

  const handleNewOrderOpen = () => {
    setShowNewOrderModal(true);
  };

  const handleNewOrderClose = () => {
    setShowNewOrderModal(false);
    dispatch(fetchOrders(parameters));
  };

  // Searchbar
  const handleSearch = () => {
    dispatch(fetchOrders(parameters));
  };

  // Handle page change for pagination
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= ordersData.pages) {
      setParameters((prevParameters) => ({
        ...prevParameters,
        page: newPage,
      }));
    }
  };

  const { orders = [], pages = 1, current_page = 1 } = ordersData; // Desestructurar con valores por defecto

  return (
    <Container className='pt-4 pb-4'>
      <Row>
        <Col>
          <Container className='pb-4 align-items-center'>
            <Row className="align-items-center">
              <Col sm='2' md='2' lg='2'>
                <h1 style={{ marginBottom: 0 }}>Pedidos</h1>
              </Col>
              <Col sm='8' md='8' lg='8'>
                <OrdersFilters onSearch={handleSearch} parameters={parameters} setParameters={setParameters} />
              </Col>
              {hasManageOrdersAccess && (
                <Col sm='2' md='2' lg='2'>
                  <Button variant='primary' style={{ marginTop: 0, padding: '0.8rem 1rem', marginLeft: '10px' }} onClick={handleNewOrderOpen}>Nuevo pedido</Button>
                </Col>
              )}
            </Row>
          </Container>
          {loading ? (
            <p>Cargando...</p>
          ) : error ? (
            <p>Error: {error}</p>
          ) : orders.length === 0 ? (
            <p>No se encontraron pedidos.</p>
          ) : (
            <div className="align-items-center">
              <ListGroup className='table-header'>
                <ListGroup.Item className='table-header mb-3'>
                  <Row>
                    <Col sm='1' md='1' lg='1' className="d-flex justify-content-center table-header-item">
                      <p><strong>ID</strong></p>
                    </Col>
                    <Col sm='2' md='2' lg='2' className="d-flex justify-content-center table-header-item">
                      <p><strong>Fecha</strong></p>
                    </Col>
                    <Col sm='3' md='3' lg='3' className="d-flex justify-content-center table-header-item">
                      <p><strong>Cliente</strong></p>
                    </Col>
                    <Col sm='3' md='3' lg='2' className="d-flex justify-content-center table-header-item">
                      <p><strong>Establecimiento</strong></p>
                    </Col>
                    <Col sm='2' md='2' lg='2' className="d-flex justify-content-center table-header-item">
                      <p><strong>Unidades</strong></p>
                    </Col>
                    <Col sm='1' md='1' lg='1' className="d-flex justify-content-center table-header-item">
                      <p><strong>Estado</strong></p>
                    </Col>
                    <Col sm='1' md='1' lg='1' className="d-flex justify-content-end">
                      <Button variant="danger" style={{ backgroundColor: '#1A3B85', borderColor: '#1A3B85' }} size="md" onClick={handleSearch}><ArrowClockwise /></Button>
                    </Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
              <ListGroup>
                {orders.map(order => (
                  <ListGroup.Item key={order.uuid} className="mb-3">
                    <Row className="align-items-center" style={{ padding: '5px 0' }}>
                      <Col sm='1' md='1' lg='1' className="d-flex justify-content-center">
                        <Card.Text><strong>#{order.code}</strong></Card.Text>
                      </Col>
                      <Col sm='2' md='2' lg='2' className="d-flex justify-content-center">
                        <Card.Text>{new Date(order.purchase_date).toLocaleDateString()}</Card.Text>
                      </Col>
                      <Col sm='3' md='3' lg='3' className="d-flex justify-content-center">
                        <Card.Text>{(order.client_first_name)} {(order.client_last_name)}</Card.Text>
                      </Col>
                      <Col sm='2' md='2' lg='2' className="d-flex justify-content-center">
                        <Card.Text>{order.establishment_name}</Card.Text>
                      </Col>
                      <Col sm='2' md='2' lg='2' className="d-flex justify-content-center">
                        <Card.Text>{order.total_quantity}</Card.Text>
                      </Col>
                      <Col sm="1" md="1" lg="1" className="d-flex justify-content-center">
                        <div style={{ '--status-color': order.status_color }} className="d-flex align-items-center">
                          <Badge pill className="custom-badge">
                            {order.status_name}
                          </Badge>
                          {loadingOrderStatuses ? (
                            <p>Cargando...</p>
                          ) : errorOrderStatuses ? (
                            <p>Error</p>
                          ) : (
                            hasManageOrdersAccess && (
                              <OrderStatusDropdown
                                orderStatuses={orderStatuses}
                                currentStatus={order.status_name}
                                handleChangeStatus={handleChangeStatus}
                                orderUuid={order.uuid}
                              />
                            )
                          )}
                        </div>
                      </Col>

                      <Col sm='1' md='1' lg='1' className="d-flex justify-content-end">
                        <Button variant="danger" style={{ backgroundColor: '#1A3B85', borderColor: '#1A3B85' }} size="md" onClick={() => handleDetailOpen(order.uuid)}><EyeFill /></Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          )}

          {/* Paginación */}
          {orders.length > 0 && (
            <div className="pagination-controls d-flex justify-content-center align-items-center mt-3">
              <Button
                variant="primary"
                className="mx-2 p-1 py-2"
                style={{ width: '100px' }}
                onClick={() => handlePageChange(current_page - 1)}
                disabled={current_page === 1}
              >
                Anterior &lt;
              </Button>
              <span className="mx-3 px-3 mt-3">Página {current_page} de {pages}</span>
              <Button
                variant="primary"
                className="mx-2 p-1 py-2"
                style={{ width: '100px' }}
                onClick={() => handlePageChange(current_page + 1)}
                disabled={current_page === pages}
              >
                &gt; Siguiente
              </Button>
            </div>
          )}

          {/* POPUPS */}
          <NewOrderModal showNewOrderModal={showNewOrderModal} handleClose={handleNewOrderClose} />
          <DetailOrderModal showDetailModal={showDetailModal} handleClose={handleDetailClose} order={order} />
        </Col>
      </Row>
    </Container>
  );
};

export default Orders;
