import { createSlice } from '@reduxjs/toolkit';
import { createProduct, deleteProductById, editProductById, getProductById, getProductsByEstablishment, getProductsWithSearch } from '../actions/productsThunks';
import { withAuthentication } from '../middlewares';

const initialState = {
    data: [],
    product: null,
    loading: false,
    error: null,
    successMessage: null,
    errorMessage: null,
};

const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        editCloseMessage(state) {
            state.successMessage = null;
            state.errorMessage = null;
        },
        //Traer todos los productos
        fetchProductsStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchProductsSuccess(state, action) {
            state.loading = false;
            state.data = action.payload;
        },
        fetchProductsFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        //Crear producto
        createNewProductStart(state) {
            state.loading = true;
            state.error = null;
        },
        createNewProductSuccess(state, action) {
            state.loading = false;
            state.product = action.payload;
            state.successMessage = 'Producto creado exitosamente';
            state.errorMessage = '';
        },
        createNewProductFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.errorMessage = 'Error al crear producto';
            state.successMessage = '';
        },
        //Borrar un producto
        deleteProductStart(state) {
            state.loading = true;
            state.error = null;
        },
        deleteProductSuccess(state, action) {
            state.loading = false;
            state.error = null;
            //Borrar producto del estado
            const productuuid = action.payload;
            const deletedProductIndex = state.data.findIndex(product => product.uuid === productuuid);

            if (deletedProductIndex !== -1) {
                state.data.splice(deletedProductIndex, 1);
                if (state.product && state.product.uuid === productuuid) {
                    state.product = null;
                }
            }
        },
        deleteProductFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        //Traer un producto para edición/detalle
        fetchProductStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchProductSuccess(state, action) {
            state.loading = false;
            state.product = action.payload;
        },
        fetchProductFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        clearProduct(state) {
            state.product = null;
        },
        //Editar un producto
        editProductStart(state) {
            state.loading = true;
            state.error = null;
        },
        editProductSuccess(state, action) {
            state.loading = false;
            state.product = action.payload;
            state.successMessage = 'Operación exitosa';
            state.errorMessage = '';
        },
        editProductFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.errorMessage = 'Error en la operación';
            state.successMessage = '';
        },
    },
});

export const fetchProducts = withAuthentication((authToken, productname) => async (dispatch, getState) => {
    dispatch(fetchProductsStart());
    try {
        const products = await getProductsWithSearch(authToken, productname);
        dispatch(fetchProductsSuccess(products));
    } catch (error) {
        dispatch(fetchProductsFailure(error.message));
    }
});

export const fetchProductsByEstablishment = withAuthentication((authToken, uuid_establishment) => async (dispatch, getState) => {
    dispatch(fetchProductsStart());
    try {
        const products = await getProductsByEstablishment(authToken, uuid_establishment);
        dispatch(fetchProductsSuccess(products));
    } catch (error) {
        dispatch(fetchProductsFailure(error.message));
    }
});

export const createNewProduct = withAuthentication((authToken, productname) => async (dispatch, getState) => {
    dispatch(createNewProductStart());
    try {
        const product = await createProduct(authToken, productname);
        dispatch(createNewProductSuccess(product));
    } catch (error) {
        dispatch(createNewProductFailure(error.message));
    }
});

export const fetchProductById = withAuthentication((authToken, productuuid) => async (dispatch, getState) => {
    dispatch(fetchProductStart());
    try {
        const product = await getProductById(authToken, productuuid);
        dispatch(fetchProductSuccess(product));
    } catch (error) {
        dispatch(fetchProductFailure(error.message));
    }
});

export const deleteProduct = withAuthentication((authToken, productuuid) => async (dispatch, getState) => {
    dispatch(deleteProductStart());
    try {
        await deleteProductById(authToken, productuuid)
        dispatch(deleteProductSuccess(productuuid));
    } catch (error) {
        dispatch(deleteProductFailure(error.message));
    }
});

export const editProduct = withAuthentication((authToken, productuuid, productname) => async (dispatch, getState) => {
    dispatch(editProductStart());
    try {
        const product = await editProductById(authToken, productuuid, productname)
        dispatch(editProductSuccess(product));
    } catch (error) {
        dispatch(editProductFailure(error.message));
    }
});

export const {
    editCloseMessage,
    fetchProductsStart,
    fetchProductsSuccess,
    fetchProductsFailure,
    createNewProductStart,
    createNewProductSuccess,
    createNewProductFailure,
    fetchProductStart,
    fetchProductSuccess,
    fetchProductFailure,
    deleteProductStart,
    deleteProductSuccess,
    deleteProductFailure,
    editProductStart,
    editProductSuccess,
    editProductFailure,
    clearProduct
} = productsSlice.actions;

export const selectProducts = (state) => state.products.data;
export const selectProductsLoading = (state) => state.products.loading;
export const selectProductsError = (state) => state.products.error;
export const selectProduct = (state) => state.products.product;
export const selectSuccessMessage = (state) => state.products.successMessage;
export const selectErrorMessage = (state) => state.products.errorMessage;

export default productsSlice.reducer;
