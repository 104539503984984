import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Col, Container, Row, ToggleButton } from 'react-bootstrap';
import EmpleadosView from './EmployeesView';
import ClientsView from './ClientsView';
import SearchBar from '../../components/Searchbar';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClients, fetchEmployees, selectUsers, selectUsersError, selectUsersLoading, clearUser, selectUserById } from '../../redux/reducers/usersReducer';
import { selectLoggedUserProfile } from '../../redux/reducers/authReducer';
import NewUserModal from './NewUserModal';
import DeleteUserModal from './DeleteUserModal';
import EditUserModal from './EditUserModal';

const Users = () => {
  const dispatch = useDispatch();
  const loading = useSelector(selectUsersLoading);
  const users = useSelector(selectUsers);
  const error = useSelector(selectUsersError);
  const profilesAuth = useSelector(selectLoggedUserProfile);
  const user = useSelector(selectUserById);

  const hasViewEmployeesAccess = profilesAuth?.some(profile =>
    profile.accesses?.some(access => access.name === 'view_employees')
  );

  const hasViewClientsAccess = profilesAuth?.some(profile =>
    profile.accesses?.some(access => access.name === 'view_clients')
  );

  const hasManageClientsAccess = profilesAuth?.some(profile =>
    profile.accesses?.some(access => access.name === 'manage_clients')
  );

  const hasManageEmployeesAccess = profilesAuth?.some(profile =>
    profile.accesses?.some(access => access.name === 'manage_employees')
  );

  const initialRadioValue = hasViewEmployeesAccess && !hasViewClientsAccess
    ? '1'
    : !hasViewEmployeesAccess && hasViewClientsAccess
      ? '2'
      : '1';

  const [radioValue, setRadioValue] = useState(initialRadioValue);

  useEffect(() => {
    if (hasViewEmployeesAccess && !hasViewClientsAccess) {
      setRadioValue('1');
    } else if (!hasViewEmployeesAccess && hasViewClientsAccess) {
      setRadioValue('2');
    }
  }, [hasViewEmployeesAccess, hasViewClientsAccess]);

  useEffect(() => {
    if (radioValue === '1') {
      dispatch(fetchEmployees(''));
    } else if (radioValue === '2') {
      dispatch(fetchClients(''));
    }
  }, [dispatch, radioValue]);

  const handleSearch = (text) => {
    if (radioValue === '1') {
      dispatch(fetchEmployees(text));
    } else {
      dispatch(fetchClients(text));
    }
  };

  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showNewUserModal, setShowNewUserModal] = useState(false);

  const handleNewUserOpen = () => {
    setShowNewUserModal(true);
  };

  const handleNewUserClose = () => {
    setShowNewUserModal(false);
    if (radioValue === '1') {
      dispatch(fetchEmployees(''));
    } else {
      dispatch(fetchClients(''));
    }
  };

  const handleDeleteClose = () => {
    dispatch(clearUser());
    setShowDeleteModal(false);
    if (radioValue === '1') {
      dispatch(fetchEmployees(''));
    } else if (radioValue === '2') {
      dispatch(fetchClients(''));
    }
  };

  const handleEditClose = () => {
    dispatch(clearUser());
    setShowEditModal(false);
    if (radioValue === '1') {
      dispatch(fetchEmployees(''));
    } else if (radioValue === '2') {
      dispatch(fetchClients(''));
    }
  };

  return (
    <Container className='pt-4 pb-4'>
      <Container className='pb-4 align-items-center'>
        <Row className="align-items-center">
          <Col sm='3' md='3' lg='3'>
            <h1 style={{ marginBottom: 0 }}>Usuarios</h1>
          </Col>
          <Col sm='7' md='7' lg='7'>
            <SearchBar onSearch={handleSearch} parameterName={"nombre o apellido"} />
          </Col>
          <Col sm='2' md='2' lg='2'>
            {(hasManageClientsAccess || hasManageEmployeesAccess) && (
              <Button variant='primary' style={{ marginTop: 0, padding: '0.8rem 1rem', marginLeft: '10px' }} onClick={handleNewUserOpen}>Nuevo usuario</Button>
            )}
          </Col>
        </Row>
      </Container>
      <Container className='pb-4 d-flex justify-content-center'>
        <ButtonGroup toggle='1'>
          {hasViewEmployeesAccess && (
            <ToggleButton
              id={'radio-1'}
              type="radio"
              variant={'outline-primary '}
              name="radio"
              value='1'
              checked={radioValue === '1'}
              onChange={() => setRadioValue('1')}
            >
              Colaboradores
            </ToggleButton>
          )}
          {hasViewClientsAccess && (
            <ToggleButton
              id={'radio-2'}
              type="radio"
              variant={'outline-primary'}
              name="radio"
              value='2'
              checked={radioValue === '2'}
              onChange={() => setRadioValue('2')}
            >
              Clientes
            </ToggleButton>
          )}
        </ButtonGroup>
      </Container>
      {loading ? (
        <p>Cargando...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : users.length === 0 ? (
        <p>No se encontraron usuarios</p>
      ) : radioValue === '1' ? (
        hasViewEmployeesAccess ? (
          <EmpleadosView employees={users} setShowDeleteModal={setShowDeleteModal} setShowEditModal={setShowEditModal} />
        ) : (
          <p>No tiene permisos para ver empleados.</p>
        )
      ) : (
        hasViewClientsAccess ? (
          <ClientsView clients={users} setShowDeleteModal={setShowDeleteModal} setShowEditModal={setShowEditModal} />
        ) : (
          <p>No tiene permisos para ver clientes.</p>
        )
      )}

      <NewUserModal handleClose={handleNewUserClose} showNewUserModal={showNewUserModal} />
      <EditUserModal showEditModal={showEditModal} handleClose={handleEditClose} user={user} />
      <DeleteUserModal showDeleteModal={showDeleteModal} handleClose={handleDeleteClose} user={user} />
    </Container>
  );
}

export default Users;
