export const generateCertification = async (token, certificationParameters) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/certifications2`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                //'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(certificationParameters)
        });

        if (!response.ok) {
            throw new Error(`Failed operation: ${response.statusText}`);
        }

        const blob = await response.blob();
        const contentDisposition = response.headers.get('Content-Disposition');
        let filename = 'downloaded_file.xlsx';

        if (contentDisposition) {
            const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
            if (filenameMatch != null && filenameMatch[1]) {
                filename = filenameMatch[1].replace(/['"]/g, '');
            }
        }

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();

        return { success: true };
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};
