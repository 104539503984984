import React from 'react';
import { Container } from 'react-bootstrap';

const Home = () => {
  return (
    <Container className="d-flex justify-content-center align-items-center vh-100">
      <div className="text-center">
        <h2 className="mb-4">¡Bienvenido al Panel Administrativo de Saigro!</h2>
        <p className="lead">Estamos encantados de tenerte aquí. Utiliza el menú de la izquierda para explorar y gestionar tus opciones de manera sencilla y eficiente.</p>
      </div>
    </Container>
  );
};

export default Home;
