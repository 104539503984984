import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, ListGroup, Button, ButtonGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfiles, fetchProfileById, selectProfiles, selectProfilesLoading, selectProfilesError, selectProfileById, clearProfile } from '../../redux/reducers/profileReducer'
import { selectLoggedUserProfile } from '../../redux/reducers/authReducer';
import { PencilSquare, Trash } from 'react-bootstrap-icons';
import SearchBar from '../../components/Searchbar';
import EditProfileModal from './EditProfileModal';
import DeleteProfileModal from './DeleteProfileModal';
import NewProfileModal from './NewProfileModal';

const Profile = () => {
  // Llamadas a API y Redux
  const dispatch = useDispatch();
  const loading = useSelector(selectProfilesLoading);
  const profiles = useSelector(selectProfiles);
  const error = useSelector(selectProfilesError);
  const profile = useSelector(selectProfileById);
  const profilesAuth = useSelector(selectLoggedUserProfile);

  useEffect(() => {
    dispatch(fetchProfiles(null));
  }, [dispatch]);

  const hasManageProfileAccess = profilesAuth?.some(profile =>
    profile.accesses?.some(access => access.name === 'manage_profiles')
  );

  //Popups
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showNewProfileModal, setShowNewProfileModal] = useState(false);

  const handleEditOpen = (profileuuid) => {
    dispatch(fetchProfileById(profileuuid));
    setShowEditModal(true);
  };

  const handleEditClose = () => {
    dispatch(clearProfile());
    setShowEditModal(false);
  };

  const handleDeleteOpen = (profileuuid) => {
    dispatch(fetchProfileById(profileuuid));
    setShowDeleteModal(true);
  };

  const handleDeleteClose = (profileuuid) => {
    dispatch(clearProfile())
    setShowDeleteModal(false);
  };

  const handleNewProfileOpen = () => {
    setShowNewProfileModal(true);
  };

  const handleNewProfileClose = () => {
    setShowNewProfileModal(false);
  };

  //Searchbar
  const handleSearch = (text) => {
    dispatch(fetchProfiles(text));
  };

  return (
    <Container className='pt-4 pb-4'>
      <Row>
        <Col>
          <Container className='pb-4 align-items-center'>
            <Row className="align-items-center">
              <Col sm='3' md='3' lg='3'>
                <h1 style={{ marginBottom: 0 }}>Perfiles</h1>
              </Col>
              <Col sm='7' md='7' lg='7'>
                <SearchBar onSearch={handleSearch} parameterName={"nombre"} />
              </Col>
              {hasManageProfileAccess && (
                <Col sm='2' md='2' lg='2'>
                  <Button variant='primary' style={{ marginTop: 0, padding: '0.8rem 1rem', marginLeft: '10px' }} onClick={handleNewProfileOpen}>Nuevo perfil</Button>
                </Col>)}
            </Row>
          </Container>
          {loading ? (
            <p>Cargando...</p>
          ) : error ? (
            <p>Error: {error}</p>
          ) : profiles.length === 0 ? (
            <p>No se encontraron perfiles.</p>
          ) : (
            <ListGroup>
              {profiles.map(profile => (
                <ListGroup.Item key={profile.uuid} className="d-flex justify-content-between align-items-center mb-3">
                  <div>
                    <Card.Text><strong>{profile.name}</strong></Card.Text>
                    <Card.Text>Descripcion: {profile.description}</Card.Text>
                  </div>
                  {hasManageProfileAccess && (
                    <div className="d-flex">
                      <ButtonGroup>
                        <Button variant="danger" style={{ backgroundColor: '#1A3B85', borderColor: '#1A3B85' }} size="lg" onClick={() => handleEditOpen(profile.uuid)}><PencilSquare /></Button>
                        <Button variant="danger" size="lg" onClick={() => handleDeleteOpen(profile.uuid)}><Trash /></Button>
                      </ButtonGroup>
                    </div>
                  )}
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}

          {/*POPUPS*/}
          <NewProfileModal showNewProfileModal={showNewProfileModal} handleClose={handleNewProfileClose} handleSearch={handleSearch} />
          <EditProfileModal showEditModal={showEditModal} handleClose={handleEditClose} profile={profile} />
          <DeleteProfileModal showDeleteModal={showDeleteModal} handleClose={handleDeleteClose} profile={profile} />
        </Col>
      </Row>
    </Container >
  );
}

export default Profile