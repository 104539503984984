export const getEstablishmentsWithSearch = async (token, establishmentsName) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/establishments/get`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                name: establishmentsName
            })
        }
        );

        if (!response.ok) {
            throw new Error(`Failed operation: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const getEstablishmentsForCertifications = async (token) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/establishments/get_for_certification`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        }
        );

        if (!response.ok) {
            throw new Error(`Failed operation: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const createEstablishment = async (token, newEstablishment) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/establishments`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(newEstablishment)
        }
        );

        if (!response.ok) {
            throw new Error(`Failed operation: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const getEstablishmentById = async (token, idEstablishment) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/establishments/${idEstablishment}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        }
        );

        if (!response.ok) {
            throw new Error(`Failed operation: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const deleteEstablishmentById = async (token, idEstablishment) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/establishments/${idEstablishment}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        }
        );

        if (!response.ok) {
            throw new Error(`Failed operation: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const editEstablishmentById = async (token, idEstablishment, newEstablishment) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/establishments/${idEstablishment}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(newEstablishment)
        }
        );

        if (!response.ok) {
            throw new Error(`Failed operation: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};