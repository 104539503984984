import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, ListGroup, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLocations, selectLocations, selectLocationsError, selectLocationsLoading } from '../../redux/reducers/locationsReducer';
import { editCloseMessage, editEstablishment, fetchEstablishments, selectErrorMessage, selectSuccessMessage } from '../../redux/reducers/establishmentsReducer';
import { fetchProducts, selectProducts, selectProductsError, selectProductsLoading } from '../../redux/reducers/productsReducer';
import { fetchCompanies, selectCompanies, selectCompaniesError, selectCompaniesLoading } from '../../redux/reducers/companiesReducer';

const EditEstablishmentModal = ({ showEditModal, handleClose, establishment }) => {
  const dispatch = useDispatch();

  const successMessage = useSelector(selectSuccessMessage);
  const errorMessage = useSelector(selectErrorMessage);

  // Formulario
  const [formData, setFormData] = useState({
    name: '',
    code: '',
    offers_delivery: false,
    offers_dining: false,
    uuid_location: '',
    products: [],
    companies: []
  });

  const [initialFormData, setInitialFormData] = useState();
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (showEditModal && establishment) {
      const initialData = {
        name: establishment.name || '',
        code: establishment.code || '',
        offers_delivery: establishment.offers_delivery || false,
        offers_dining: establishment.offers_dining || false,
        uuid_location: establishment.uuid_location || '',
        products: establishment.products || [],
        companies: establishment.companies.map(company => company.uuid) || [] // Asegúrate de mapear solo los UUID
      };
      setFormData(initialData);
      setInitialFormData(initialData);
      console.log(initialData);
    }
  }, [showEditModal, establishment]);

  // Productos
  const loadingProducts = useSelector(selectProductsLoading);
  const products = useSelector(selectProducts);
  const errorProducts = useSelector(selectProductsError);
  useEffect(() => {
    dispatch(fetchProducts(''));
  }, [dispatch]);

  // Compañías
  const loadingCompanies = useSelector(selectCompaniesLoading);
  const companies = useSelector(selectCompanies);
  const errorCompanies = useSelector(selectCompaniesError);
  useEffect(() => {
    dispatch(fetchCompanies(''));
  }, [dispatch]);

  // Locations
  const loadingLocations = useSelector(selectLocationsLoading);
  const locations = useSelector(selectLocations);
  const errorLocations = useSelector(selectLocationsError);
  useEffect(() => {
    dispatch(fetchLocations(''));
  }, [dispatch]);

  const handleSelectLocation = (event) => {
    const selectedLocationUuid = event.target.value;
    setFormData(prevFormData => ({
      ...prevFormData,
      uuid_location: selectedLocationUuid
    }));
  };

  useEffect(() => {
    if (initialFormData && formData) {
      const hasChanges = JSON.stringify(initialFormData) !== JSON.stringify(formData);
      setIsChanged(hasChanges);
    }
  }, [formData, initialFormData]);

  // Productos
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [availableProducts, setAvailableProducts] = useState([]);
  useEffect(() => {
    if (formData.products) {
      setAvailableProducts(products.filter(product => !formData.products.includes(product.uuid)));
      setSelectedProducts(products.filter(product => formData.products.includes(product.uuid)));
    }
  }, [formData.products, products]);

  const handleSelectProduct = (event) => {
    const selectedProductId = event.target.value;
    const selectedProduct = availableProducts.find(product => product.uuid === selectedProductId);

    setSelectedProducts([...selectedProducts, selectedProduct]);
    setFormData({
      ...formData,
      products: [...formData.products, selectedProductId]
    });

    setAvailableProducts(availableProducts.filter(product => product.uuid !== selectedProductId));
  };

  const handleRemoveProduct = (productToRemove) => {
    setAvailableProducts([...availableProducts, productToRemove]);

    setSelectedProducts(selectedProducts.filter(product => product.uuid !== productToRemove.uuid));
    setFormData({
      ...formData,
      products: formData.products.filter(productId => productId !== productToRemove.uuid)
    });
  };

  // Compañías
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [availableCompanies, setAvailableCompanies] = useState([]);
  useEffect(() => {
    if (formData.companies) {
      setAvailableCompanies(companies.filter(company => !formData.companies.includes(company.uuid)));
      setSelectedCompanies(companies.filter(company => formData.companies.includes(company.uuid)));
    }
  }, [formData.companies, companies]);

  const handleSelectCompany = (event) => {
    const selectedCompanyId = event.target.value;
    const selectedCompany = availableCompanies.find(company => company.uuid === selectedCompanyId);

    setSelectedCompanies([...selectedCompanies, selectedCompany]);
    setFormData({
      ...formData,
      companies: [...formData.companies, selectedCompanyId]
    });

    setAvailableCompanies(availableCompanies.filter(company => company.uuid !== selectedCompanyId));
  };

  const handleRemoveCompany = (companyToRemove) => {
    setAvailableCompanies([...availableCompanies, companyToRemove]);

    setSelectedCompanies(selectedCompanies.filter(company => company.uuid !== companyToRemove.uuid));
    setFormData({
      ...formData,
      companies: formData.companies.filter(companyId => companyId !== companyToRemove.uuid)
    });
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleCloseMessage = () => {
    dispatch(editCloseMessage());
    if (successMessage) {
      setFormData({
        name: '',
        code: '',
        offers_delivery: false,
        offers_dining: false,
        products: [],
        companies: [],
        uuid_location: ''
      });
      handleClose();
    }
  };

  const handleSubmit = () => {
    dispatch(editEstablishment(establishment.uuid, formData));
    dispatch(fetchEstablishments(''));
  };

  return (
    <div>
      <Modal show={showEditModal} onHide={handleClose} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Editar establecimiento</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ marginLeft: '8px' }}>
          <form style={{ display: 'contents' }}>
            <div>
              <Row className='pb-4'>
                <Col sm='6' md='6' lg='6'>
                  <Form.Control
                    name='name'
                    type="text"
                    placeholder="Nombre..."
                    value={formData.name}
                    onChange={handleFormChange}
                    style={{ marginTop: 0, height: '100%' }}
                  />
                </Col>
                <Col sm='6' md='6' lg='6'>
                  <Form.Control
                    name='code'
                    type="text"
                    placeholder="Código..."
                    value={formData.code}
                    onChange={handleFormChange}
                    style={{ marginTop: 0, height: '100%' }}
                  />
                </Col>
              </Row>
              <Row className='pb-2'>
                {loadingLocations ? (
                  <p>Cargando ubicaciones...</p>
                ) : errorLocations ? (
                  <p>Error: {errorLocations}</p>
                ) : locations.length === 0 ? (
                  <p>No se encontraron ubicaciones</p>
                ) : (
                  <Col m='5' md='5' lg='5'>
                    <Form.Select aria-label="Selecciona una ubicación" className='form-control' onChange={handleSelectLocation} value={formData.uuid_location}>
                      <option value="">Selecciona una ubicación</option>
                      {locations.map(locations => (
                        <option key={locations.uuid} value={locations.uuid}>{locations.name}</option>
                      ))}
                    </Form.Select>
                  </Col>
                )}
                <Col m='7' md='7' lg='7'>
                  <h6>Seleccione respectivamente</h6>
                  <Row>
                    <Col m='6' md='6' lg='6'>
                      <Form.Check
                        type="checkbox"
                        label='Kiosko'
                        onChange={(e) => setFormData({ ...formData, offers_delivery: e.target.checked })}
                        checked={formData.offers_delivery}
                      />
                    </Col>
                    <Col m='6' md='6' lg='6'>
                      <Form.Check
                        type="checkbox"
                        label='Comedor'
                        onChange={(e) => setFormData({ ...formData, offers_dining: e.target.checked })}
                        checked={formData.offers_dining}
                      />
                    </Col>
                  </Row>
                </Col>
                <h5 className='pt-3 pb-2'>Productos asociados</h5>
                {loadingProducts ? (
                  <p>Cargando productos...</p>
                ) : errorProducts ? (
                  <p>Error: {errorProducts}</p>
                ) : products.length === 0 ? (
                  <p>No se encontraron productos.</p>
                ) : (
                  <Row>
                    <Col m='7' md='7' lg='7'>
                      <ListGroup>
                        {selectedProducts.length === 0 ? (
                          <p>Sin productos seleccionados aún</p>
                        ) : (selectedProducts.map(product => (
                          <ListGroup.Item key={product.uuid} className="d-flex justify-content-between align-items-center mb-3">
                            <div>
                              <Card.Text style={{ marginBottom: '0rem' }}><strong>{product.name}</strong></Card.Text>
                              <Card.Text>Código: {product.code}</Card.Text>
                            </div>
                            <div className="d-flex">
                              <Button variant="danger" size="md" onClick={() => handleRemoveProduct(product)}>x</Button>
                            </div>
                          </ListGroup.Item>
                        )))}
                      </ListGroup>
                    </Col>
                    <Col m='5' md='5' lg='5'>
                      <Form.Select aria-label="Agregar productos asociados" className='form-control' onChange={handleSelectProduct}>
                        <option value="">Agregar productos asociados</option>
                        {availableProducts.map(product => (
                          <option key={product.uuid} value={product.uuid}>{product.name}</option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                )}
                <h5 className='pt-3 pb-2'>Compañías asociadas</h5>
                {loadingCompanies ? (
                  <p>Cargando compañías...</p>
                ) : errorCompanies ? (
                  <p>Error: {errorCompanies}</p>
                ) : companies.length === 0 ? (
                  <p>No se encontraron compañías.</p>
                ) : (
                  <Row>
                    <Col m='7' md='7' lg='7'>
                      <ListGroup>
                        {selectedCompanies.length === 0 ? (
                          <p>Sin compañías seleccionadas aún</p>
                        ) : (selectedCompanies.map(company => (
                          <ListGroup.Item key={company.uuid} className="d-flex justify-content-between align-items-center mb-3">
                            <div>
                              <Card.Text style={{ marginBottom: '0rem' }}><strong>{company.name}</strong></Card.Text>
                              <Card.Text> </Card.Text>
                            </div>
                            <div className="d-flex">
                              <Button variant="danger" size="md" onClick={() => handleRemoveCompany(company)}>x</Button>
                            </div>
                          </ListGroup.Item>
                        )))}
                      </ListGroup>
                    </Col>
                    <Col m='5' md='5' lg='5'>
                      <Form.Select aria-label="Agregar compañías asociadas" className='form-control' onChange={handleSelectCompany}>
                        <option value="">Agregar compañías asociadas</option>
                        {availableCompanies.map(company => (
                          <option key={company.uuid} value={company.uuid}>{company.name}</option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                )}
              </Row>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          {!isChanged && (
            <p className="form-text text-muted">
              Debe realizar cambios para guardar
            </p>
          )}
          <Button variant="primary" style={{ marginTop: '0px' }} onClick={handleSubmit} disabled={!isChanged}>Guardar cambios</Button>
        </Modal.Footer>
        <Modal show={!!successMessage || !!errorMessage} onHide={handleCloseMessage}>
          <Modal.Header closeButton>
            <Modal.Title>{errorMessage ? 'Error en la edición' : 'Edición exitosa'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {successMessage || errorMessage}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseMessage}>Cerrar</Button>
          </Modal.Footer>
        </Modal>
      </Modal >
    </div>
  )
}

export default EditEstablishmentModal;
