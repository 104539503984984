import { createSlice } from '@reduxjs/toolkit';
import { createLocation, deleteLocationById, editLocationById, getLocationById, getLocationsWithSearch } from '../actions/locationsThunks';
import { withAuthentication } from '../middlewares';

const initialState = {
    data: [],
    location: null,
    loading: false,
    error: null,
    successMessage: '',
    errorMessage: '',
};

const locationsSlice = createSlice({
    name: 'locations',
    initialState,
    reducers: {
        editCloseMessage(state) {
            state.successMessage = '';
            state.errorMessage = '';
        },
        //Traer todos los ubicaciones
        fetchLocationsStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchLocationsSuccess(state, action) {
            state.loading = false;
            state.data = action.payload;
        },
        fetchLocationsFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        //Crear ubicacion
        createNewLocationStart(state) {
            state.loading = true;
            state.error = null;
        },
        createNewLocationSuccess(state, action) {
            state.loading = false;
            state.location = action.payload;
            state.successMessage = 'Ubicación creada exitosamente';
            state.errorMessage = '';
        },
        createNewLocationFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.errorMessage = 'Error al crear ubicación';
            state.successMessage = '';
        },
        //Borrar una ubicación
        deleteLocationStart(state) {
            state.loading = true;
            state.error = null;
        },
        deleteLocationSuccess(state, action) {
            state.loading = false;
            state.error = null;
            //Borrar ubicación del estado
            const locationuuid = action.payload;
            const deletedLocationIndex = state.data.findIndex(location => location.uuid === locationuuid);

            if (deletedLocationIndex !== -1) {
                state.data.splice(deletedLocationIndex, 1);
                if (state.location && state.location.uuid === locationuuid) {
                    state.location = null;
                }
            }
        },
        deleteLocationFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        //Traer una ubicación para edición/detalle
        fetchLocationStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchLocationSuccess(state, action) {
            state.loading = false;
            state.location = action.payload;
        },
        fetchLocationFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        clearLocation(state) {
            state.location = null;
        },
        //Editar una ubicación
        editLocationStart(state) {
            state.loading = true;
            state.error = null;
        },
        editLocationSuccess(state, action) {
            state.loading = false;
            state.location = action.payload;
            state.successMessage = 'Operación exitosa';
            state.errorMessage = '';
        },
        editLocationFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.errorMessage = 'Error en la operación';
            state.successMessage = '';
        },
    },
});

export const fetchLocations = withAuthentication((authToken, locationName) => async (dispatch, getState) => {
    dispatch(fetchLocationsStart());
    try {
        const locations = await getLocationsWithSearch(authToken, locationName);
        dispatch(fetchLocationsSuccess(locations));
    } catch (error) {
        dispatch(fetchLocationsFailure(error.message));
    }
});

export const createNewLocation = withAuthentication((authToken, locationName) => async (dispatch, getState) => {
    dispatch(createNewLocationStart());
    try {
        const location = await createLocation(authToken, locationName);
        dispatch(createNewLocationSuccess(location));
    } catch (error) {
        dispatch(createNewLocationFailure(error.message));
    }
});

export const fetchLocationById = withAuthentication((authToken, locationuuid) => async (dispatch, getState) => {
    dispatch(fetchLocationStart());
    try {
        const location = await getLocationById(authToken, locationuuid);
        dispatch(fetchLocationSuccess(location));
    } catch (error) {
        dispatch(fetchLocationFailure(error.message));
    }
});

export const deleteLocation = withAuthentication((authToken, locationuuid) => async (dispatch, getState) => {
    dispatch(deleteLocationStart());
    try {
        await deleteLocationById(authToken, locationuuid)
        dispatch(deleteLocationSuccess(locationuuid));
    } catch (error) {
        dispatch(deleteLocationFailure(error.message));
    }
});

export const editLocation = withAuthentication((authToken, locationuuid, locationName) => async (dispatch, getState) => {
    dispatch(editLocationStart());
    try {
        const location = await editLocationById(authToken, locationuuid, locationName)
        dispatch(editLocationSuccess(location));
    } catch (error) {
        dispatch(editLocationFailure(error.message));
    }
});

export const {
    editCloseMessage,
    fetchLocationsStart,
    fetchLocationsSuccess,
    fetchLocationsFailure,
    createNewLocationStart,
    createNewLocationSuccess,
    createNewLocationFailure,
    fetchLocationStart,
    fetchLocationSuccess,
    fetchLocationFailure,
    deleteLocationStart,
    deleteLocationSuccess,
    deleteLocationFailure,
    editLocationStart,
    editLocationSuccess,
    editLocationFailure,
    clearLocation
} = locationsSlice.actions;

export const selectLocations = (state) => state.locations.data;
export const selectLocationsLoading = (state) => state.locations.loading;
export const selectLocationsError = (state) => state.locations.error;
export const selectLocation = (state) => state.locations.location;
export const selectSuccessMessage = (state) => state.locations.successMessage;
export const selectErrorMessage = (state) => state.locations.errorMessage;

export default locationsSlice.reducer;
