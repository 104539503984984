import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col, InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchModules, selectModules, selectModulesError, selectModulesLoading } from '../../redux/reducers/modulesReducer';
import { fetchAccesses, selectAccesses, selectAccessesError, selectAccessesLoading } from '../../redux/reducers/accessesReducer';
import { createNewProfile, editCloseMessage, fetchProfiles } from '../../redux/reducers/profileReducer';
import { selectLoggedUserProfile } from '../../redux/reducers/authReducer';

const NewProfileModal = ({ showNewProfileModal, handleClose, handleSearch }) => {
  // Llamadas a API y Redux
  const dispatch = useDispatch();
  const loadingModules = useSelector(selectModulesLoading);
  const modules = useSelector(selectModules);
  const errorModules = useSelector(selectModulesError);

  const loadingAccesses = useSelector(selectAccessesLoading);
  const accesses = useSelector(selectAccesses);
  const errorAccesses = useSelector(selectAccessesError);

  //Mensaje de exito o error
  const successMessage = useSelector(state => state.profile.successMessage);
  const errorMessage = useSelector(state => state.profile.errorMessage);

  const profilesAuth = useSelector(selectLoggedUserProfile);

  const hasAssignAccesessAccess = profilesAuth?.some(profile =>
    profile.accesses?.some(access => access.name === 'assign_accesses')
  );

  const handleCloseMessage = () => {
    handleClose();
    dispatch(editCloseMessage());
  }

  //Accesos
  useEffect(() => {
    dispatch(fetchAccesses());
  }, [dispatch]);

  //Modulos
  useEffect(() => {
    dispatch(fetchModules());
  }, [dispatch]);

  const chunkArray = (arr, chunkSize) => {
    const arrCopy = [...arr];
    arrCopy.sort((a, b) => a.name.localeCompare(b.name));

    const chunks = [];
    for (let i = 0; i < arrCopy.length; i += chunkSize) {
      chunks.push(arrCopy.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const chunkedModules = chunkArray(modules, 3);

  //Logica checkboxes
  const [checkedAccesses, setCheckedAccesses] = useState([]);

  const handleAccessChange = (accessUuid) => {
    setCheckedAccesses(prevAccesses => {
      if (prevAccesses.includes(accessUuid)) {
        return prevAccesses.filter(uuid => uuid !== accessUuid);
      } else {
        return [...prevAccesses, accessUuid];
      }
    });
  };

  //Nombre y descripcion
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(createNewProfile(JSON.stringify({
      name: name,
      description: description,
      uuid_accesses: checkedAccesses
    })))
      .then(() => {
        dispatch(fetchProfiles(null));
        handleSearch();
      });
    setName('')
    setCheckedAccesses([])
    setDescription("")
    handleClose();
  };

  return (
    <div>
      <Modal show={showNewProfileModal} onHide={handleClose} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Nuevo perfil</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ marginLeft: '8px' }}>
          <form style={{ display: 'contents' }} onSubmit={handleSubmit}>
            <div>
              <Row className='pb-2'>
                <Col sm='7' md='7' lg='7'>
                  <InputGroup>
                    <InputGroup.Text>Nombre:</InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Nombre del perfil..."
                      value={name}
                      onChange={handleNameChange}
                      style={{ marginTop: 0, height: '100%' }}
                      required
                    />
                  </InputGroup>
                </Col>
              </Row>
              <Row className='pb-4'>
                <Col sm='7' md='7' lg='7'>
                  <InputGroup>
                    <InputGroup.Text>Descripción:</InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Descripción del perfil..."
                      value={description}
                      onChange={handleDescriptionChange}
                      style={{ marginTop: 0, height: '100%' }}
                      required
                    />
                  </InputGroup>
                </Col>
              </Row>
            </div>
            <h4 className='pb-4'>Permisos y accesos</h4>
            {loadingModules ? (
              <p>Cargando módulos...</p>
            ) : errorModules ? (
              <p>Error: {errorModules}</p>
            ) : modules.length === 0 ? (
              <p>No se encontraron módulos</p>
            ) : (
              <>
                {chunkedModules.map((chunk, index) => (
                  <Row key={index} className="mb-3" style={{ marginLeft: '1rem' }}>
                    {chunk.map(module => (
                      <Col key={module.uuid} md={4} className="pb-3">
                        <h5>{module.name}</h5>
                        {loadingAccesses ? (
                          <p>Cargando accesos...</p>
                        ) : errorAccesses ? (
                          <p>Error: {errorAccesses}</p>
                        ) : accesses.length === 0 ? (
                          <p>No se encontraron accesos</p>
                        ) : (
                          <>
                            {accesses
                              .filter(
                                access =>
                                  access.module.name === module.name
                              )
                              .sort((a, b) =>
                                a.name.startsWith('view_')
                                  ? -1
                                  : b.name.startsWith('view_')
                                    ? 1
                                    : 0
                              )
                              .map(access => (
                                <Form.Check
                                  key={access.uuid}
                                  type="checkbox"
                                  id={access.uuid}
                                  label={access.description}
                                  checked={checkedAccesses.includes(access.uuid)}
                                  onChange={() => handleAccessChange(access.uuid)}
                                  disabled={!hasAssignAccesessAccess}
                                />
                              ))}
                          </>
                        )}
                      </Col>
                    ))}
                  </Row>
                ))}
              </>
            )}
            <Modal.Footer>
              <Button variant="primary" type='submit'>Crear perfil</Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
        <Modal show={!!successMessage || !!errorMessage} onHide={handleCloseMessage}>
          <Modal.Header closeButton>
            <Modal.Title>{errorMessage ? 'Error en la creación' : 'Creación exitosa'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {successMessage || errorMessage}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseMessage}>Cerrar</Button>
          </Modal.Footer>
        </Modal>
      </Modal >


    </div>
  );
}

export default NewProfileModal;
