import React from 'react';
import { Col, Form, Button, Modal, Row, Card, Badge, ListGroup } from 'react-bootstrap';
import { PersonCircle } from 'react-bootstrap-icons';
import '../styles/Orders.css';

const DetailOrderModal = ({ showDetailModal, handleClose, order }) => {
  if (!order) {
    return null; // Return null if order is not defined to prevent errors
  }

  const { details = [] } = order; // Default to an empty array if details is undefined
  console.log(order);
  return (
    <div>
      <Modal show={showDetailModal} onHide={handleClose} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Detalle de órden</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ marginLeft: '8px' }}>
          <form style={{ display: 'contents' }}>
            <div>
              {Object.keys(order).length > 0 && (
                <>
                  <Row className='pb-4'>
                    <Col>
                      <h5>Código de órden:</h5>
                      <h5> #{order.code}</h5>
                    </Col>
                    <Col>
                      <h5>Estado actual: </h5>
                      <div style={{ '--status-color': order.status?.color }}>
                        <Badge pill className="custom-badge">
                          {order.status?.name}
                        </Badge>
                      </div>
                    </Col>
                    <Col>
                      <h5>Cantidad total de unidades: </h5>
                      <h5>{order.total_quantity}</h5>
                    </Col>
                  </Row>
                  <Row className='pb-4'>
                    <Col sm='3' md='3' lg='3'>
                      <h5>Establecimiento</h5>
                      <Form.Control
                        type="text"
                        value={order.establishment_name}
                        title={order.establishment_name}
                        style={{ marginTop: 0 }}
                        readOnly
                        disabled
                      />
                    </Col>
                    <Col sm='4' md='4' lg='4'>
                      <h5>Cliente</h5>
                      <Card>
                        <Card.Body style={{ padding: '9px' }}>
                          <Row className="d-flex align-items-center">
                            <Col sm='2' md='2' lg='2'>
                              <PersonCircle size={30} />
                            </Col>
                            <Col sm='5' md='5' lg='5'>
                              {order.client_first_name} {order.client_last_name}
                            </Col>
                            <Col sm='4' md='4' lg='4'>
                              <strong>{order.client_dni}</strong>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col sm='3' md='3' lg='3'>
                      <h5>Empresa</h5>
                      <Form.Control
                        type="text"
                        value={order.company_name}
                        title={order.company_name}
                        style={{ marginTop: 0 }}
                        readOnly
                        disabled
                      />
                    </Col>
                    <Col sm='2' md='2' lg='2'>
                      <h5>Fecha</h5>
                      <Form.Control
                        readOnly
                        type="text"
                        title={new Date(order.purchase_date).toLocaleDateString('es-ES', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric'
                        })}
                        value={new Date(order.purchase_date).toLocaleDateString('es-ES', {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric'
                        })}
                        style={{ marginTop: 0 }}
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm='5' md='5' lg='5'>
                      <h5>Productos asociados</h5>
                    </Col>
                    <Col sm="7" md="7" lg="7">
                      <ListGroup>
                        {details.length === 0 ? (
                          <h5>Sin productos asociados</h5>
                        ) : (
                          details.map((product) => (
                            <ListGroup.Item key={product.product_uuid} className="mb-3">
                              {/* Fila principal con nombre, código y cantidad */}
                              <Row className="d-flex align-items-center" style={{ width: '100%' }}>
                                <Col sm="5" md="5" lg="5">
                                  <Card.Text style={{ marginBottom: '0rem' }}>
                                    <strong>{product.product_name}</strong>
                                  </Card.Text>
                                </Col>
                                <Col sm="4" md="4" lg="4">
                                  <Card.Text>Código: {product.product_code}</Card.Text>
                                </Col>
                                <Col sm="3" md="3" lg="3" className="pe-4">
                                  <Form.Control
                                    readOnly
                                    type="number"
                                    value={product.quantity}
                                    min={1}
                                    style={{ height: '40px' }}
                                  />
                                </Col>
                              </Row>

                              {product.description && product.description.trim() !== '' && (
                                <Row>
                                  <Col sm="12">
                                    <Card.Text className="m-3">
                                    <strong >Observaciones del pedido:</strong><br />
                                      {product.description}
                                    </Card.Text>
                                  </Col>
                                </Row>
                              )}
                            </ListGroup.Item>
                          ))
                        )}
                      </ListGroup>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DetailOrderModal;
