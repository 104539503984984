import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEstablishments, selectEstablishments, selectEstablishmentsError, selectEstablishmentsLoading } from '../../redux/reducers/establishmentsReducer';
import { Download } from 'react-bootstrap-icons';
import { generateCertification } from '../../redux/actions/certificationThunks';

const Certifications = () => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    start_date: null,
    end_date: null,
    uuid_establishment: '',
    uuid_company: '',
    companies: []
  });

  // ESTABLISHMENTS
  const loadingEstablishments = useSelector(selectEstablishmentsLoading);
  const establishments = useSelector(selectEstablishments);
  console.log(establishments);
  const errorEstablishments = useSelector(selectEstablishmentsError);
  useEffect(() => {
    dispatch(fetchEstablishments(''));
  }, [dispatch]);

  // Handle select establishment
  const handleSelectEstablishment = (event) => {
    const selectedUuid = event.target.value;
    const selectedEstablishment = establishments.find(est => est.uuid === selectedUuid);
    setFormData(prevFormData => ({
      ...prevFormData,
      uuid_establishment: selectedUuid,
      companies: selectedEstablishment ? selectedEstablishment.companies : []
    }));
  };

  // Handle select company
  const handleSelectCompany = (event) => {
    const selectedCompanyUUID = event.target.value;
    setFormData(prevFormData => ({
      ...prevFormData,
      uuid_company: selectedCompanyUUID
    }));
  };

  // DATES
  function range(start, end, step = 1) {
    const result = [];
    for (let i = start; i < end; i += step) {
      result.push(i);
    }
    return result;
  }
  const years = range(2020, new Date().getFullYear() + 1, 1);
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const adjustDate = (date) => {
    const adjustedDate = new Date(date);
    adjustedDate.setDate(adjustedDate.getDate() + 1);
    return adjustedDate;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const certificationParameters = {
      uuid_establishment: formData.uuid_establishment,
      start_date: formatDate(new Date(new Date(formData.start_date))),
      end_date: formatDate(new Date(new Date(formData.end_date))),
      uuid_company: formData.uuid_company
    };
    try {
      await generateCertification('a', certificationParameters);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Container fluid className="mt-5">
      <h1 className="mb-4">Certificaciones</h1>
      <h4>Generar certificación</h4>
      <Form className="w-100 mb-5" style={{ maxWidth: '2000px' }} onSubmit={handleSubmit}>
        <Row className="align-items-end w-100">
          <Col md="2">
            <h5>Desde</h5>
            <DatePicker
              required
              dateFormat="yyyy/MM/dd"
              placeholderText="Fecha desde"
              className="form-control"
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
              }) => (
                <div
                  style={{
                    margin: 10,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <select
                    value={date ? date.getFullYear() : ''}
                    onChange={({ target: { value } }) => changeYear(value)}
                  >
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <select
                    value={date ? months[date.getMonth()] : ''}
                    onChange={({ target: { value } }) =>
                      changeMonth(months.indexOf(value))
                    }
                  >
                    {months.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              selected={formData.start_date ? new Date(formData.start_date) : null}
              onChange={(date) =>
                setFormData({ ...formData, start_date: formatDate(adjustDate(new Date(date))) })
              }
            />
          </Col>
          <Col md="2">
            <h5>Hasta</h5>
            <DatePicker
              required
              dateFormat="yyyy/MM/dd"
              placeholderText="Fecha hasta"
              className="form-control"
              minDate={formData.start_date ? new Date(formData.start_date) : null}
              disabled={!formData.start_date}
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
              }) => (
                <div
                  style={{
                    margin: 10,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <select
                    value={date ? date.getFullYear() : ''}
                    onChange={({ target: { value } }) => changeYear(value)}
                  >
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <select
                    value={date ? months[date.getMonth()] : ''}
                    onChange={({ target: { value } }) =>
                      changeMonth(months.indexOf(value))
                    }
                  >
                    {months.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              selected={formData.end_date ? new Date(formData.end_date) : null}
              onChange={(date) =>
                setFormData({ ...formData, end_date: formatDate(adjustDate(new Date(date))) })
              }
            />
          </Col>
          <Col md="3">
            <h5>Establecimiento</h5>
            {loadingEstablishments ? (
              <p>Cargando establecimientos...</p>
            ) : errorEstablishments ? (
              <p>Error: {errorEstablishments}</p>
            ) : establishments.length === 0 ? (
              <p>No se encontraron establecimientos.</p>
            ) : (
              <Form.Select
                aria-label="Seleccione establecimiento"
                className="form-control"
                onChange={handleSelectEstablishment}
                required
              >
                <option value="">Seleccione establecimiento</option>
                {establishments.map((establishment) => (
                  <option key={establishment.uuid} value={establishment.uuid}>
                    {establishment.name}
                  </option>
                ))}
              </Form.Select>
            )}
          </Col>
          <Col md='4'>
            <h5>Compañías</h5>
            <Form.Select
              aria-label="Selecciona una compañía"
              className='form-control'
              onChange={handleSelectCompany}
              value={formData.uuid_company}
              disabled={formData.companies.length === 0}
            >
              <option value="">
                {formData.companies.length === 0 ? 'Seleccione establecimiento' : 'Todas las compañías'}
              </option>
              {formData.companies.map(company => (
                <option key={company.uuid} value={company.uuid}>{company.name}</option>
              ))}
            </Form.Select>
          </Col>
          <Col md="1">
            <Button className="w-100" type='submit'><Download /></Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default Certifications;